import { ReactNode, useEffect, useRef } from "react";
import {
  Dialog,
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay,
  Button as AriaButton,
  ModalOverlayProps,
} from "react-aria-components";
import { Button } from "common/components/base";

import XMark from "fontawesome/regular/xmark.svg?react";
import ArrowLeft from "fontawesome/regular/arrow-left.svg?react";

import { clsx } from "clsx";

export function Modal({
  isOpen,
  setIsOpen,
  onAfterClose,
  children,
  className,
  variant,
}: {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onAfterClose?: () => void;
  children: (({ close }: { close: () => void }) => ReactNode) | ReactNode;
  className?: string;
  variant: "white" | "grey";
}) {
  return (
    <ModalOverlay isDismissable isOpen={isOpen} onOpenChange={setIsOpen}>
      <AriaModal
        className={({ isExiting, isEntering }) =>
          clsx(
            "relative max-md:h-full max-md:w-full",
            isEntering && "animate-in md:zoom-in-90",
            isExiting && "animate-out md:zoom-out-90",
          )
        }
      >
        {({ state: { isOpen } }) => (
          <DialogContainer
            className={className}
            isOpen={isOpen}
            onAfterClose={onAfterClose}
            variant={variant}
          >
            {children}
          </DialogContainer>
        )}
      </AriaModal>
    </ModalOverlay>
  );
}
function DialogContainer({
  className,
  children,
  isOpen,
  onAfterClose,
  variant,
}: {
  className?: string;
  children: (({ close }: { close: () => void }) => ReactNode) | ReactNode;
  isOpen: boolean;
  onAfterClose?: () => void;
  variant: "white" | "grey";
}) {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (!isOpen) {
      if (onAfterClose) {
        timeoutRef.current = window.setTimeout(onAfterClose, 300);
        return () => clearTimeout(timeoutRef.current);
      }
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [isOpen, onAfterClose]);

  return (
    <Dialog
      className={clsx(
        variant === "white" ? "bg-white" : "bg-grey-50",
        "flex px-2 py-4 outline-none max-md:h-full md:max-h-[90%] md:rounded-5xl md:p-6",
        className,
      )}
    >
      {({ close }) => (
        <>
          <AriaButton
            onPress={close}
            className="group absolute right-6 top-6 z-base max-md:hidden"
          >
            <XMark className="size-6 fill-grey-500 group-hover:fill-grey-900" />
          </AriaButton>
          <Button
            variant="stroke"
            size="md"
            onPress={close}
            className="absolute left-2 top-4 md:hidden"
            isIconOnly
          >
            <ArrowLeft />
          </Button>
          {typeof children === "function" ? children({ close }) : children}
        </>
      )}
    </Dialog>
  );
}
export function ModalOverlay(props: Omit<ModalOverlayProps, "className">) {
  return (
    <AriaModalOverlay
      className={({ isExiting, isEntering }) =>
        clsx(
          `fixed top-0 z-modal flex h-[--visual-viewport-height] w-screen items-center justify-center bg-grey-900/20 backdrop-blur-sm`,
          isEntering && "animate-in fade-in",
          isExiting && "animate-out fade-out",
        )
      }
      {...props}
    />
  );
}
