import { Dialog } from "react-aria-components";

import TriangleExclamationIcon from "fontawesome/solid/triangle-exclamation.svg?react";
import { Button, DialogHeading } from "common/components/base";
import { clsx } from "clsx";

export default function ErrorDialog({
  isOpen,
  onRetry,
}: {
  isOpen: boolean;
  onRetry: () => void;
}) {
  return (
    <div
      className={clsx(
        "absolute inset-0 z-modal flex rounded-xl bg-grey-900/20 transition-opacity lg:rounded-6xl",
        isOpen ? "opacity-100" : "pointer-events-none opacity-0",
      )}
    >
      <Dialog
        className={clsx(
          "m-auto flex w-[90%] flex-col items-center rounded-5xl bg-white p-6 pb-8 shadow-sm transition-transform lg:w-[450px]",
          isOpen ? "scale-100" : "scale-90",
        )}
      >
        <TriangleExclamationIcon className="mb-3 mt-6 w-6" />
        <DialogHeading className="mb-2 text-2xl font-medium">
          Something went wrong
        </DialogHeading>
        <span className="mb-6 text-center">
          Don&apos;t worry, it&apos;s not you - we failed to fetch the freshest
          data from Google. Wait a couple of seconds and try again, but if the
          issue continues to occur, contact our support team.
        </span>
        <Button size="lg" variant="black" onPress={onRetry} className="w-full">
          Try again
        </Button>
      </Dialog>
    </div>
  );
}
