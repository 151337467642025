import { ReactNode } from "react";
import { RankedPlace } from "common/types.ts";
import { getRankColorClassnames, getRankLabel } from "common/utils.ts";
import StarFullIcon from "fontawesome/solid/star.svg?react";
import { Badge, Skeleton } from "common/components/base";
import { clsx } from "clsx";

export default function PlacesListItem({ place }: { place?: RankedPlace }) {
  const labelComponents: ReactNode[] = [];

  if (place) {
    if (place.primary_category) {
      labelComponents.push(
        <Badge size="sm" variant="grey" key={place.primary_category}>
          {place.primary_category}
        </Badge>,
      );
    }
    place.categories.forEach((category) => {
      labelComponents.push(
        <Badge size="sm" variant="grey" key={category}>
          {category}
        </Badge>,
      );
    });
  } else {
    for (let i = 0; i < 3; i++) {
      labelComponents.push(<Badge size="sm" variant="skeleton" key={i} />);
    }
  }
  return (
    <div className="flex flex-col border-t border-t-grey-300 py-4 last:border-b-0">
      <div className="mb-4 flex items-center gap-2">
        <div className="flex grow flex-row items-center justify-between gap-x-2 overflow-hidden">
          {place ? (
            <>
              <div
                className={clsx(
                  "flex size-8 shrink-0 items-center justify-center rounded-full",
                  getRankColorClassnames(place.average_rank).bg,
                )}
              >
                <span className="text-xs font-medium text-grey-900">
                  {getRankLabel(place.average_rank)}
                </span>
              </div>
              <span className="truncate text-base font-medium">
                {place.name}
              </span>
              <div className="ml-auto flex items-center gap-x-1">
                <StarFullIcon className="size-4 fill-yellow-300" />
                <span className="text-sm font-medium">{place.rating}</span>
                <span className="text-sm font-normal text-grey-500">
                  ({place.rating_count})
                </span>
              </div>
            </>
          ) : (
            <>
              <Skeleton className="size-8 rounded-full" />
              <Skeleton className="h-6 w-36 rounded-2xl" />
              <StarFullIcon className="ml-auto size-4 animate-pulse fill-grey-300" />
            </>
          )}
        </div>
      </div>
      {labelComponents.length && (
        <div className="flex flex-wrap gap-2">{labelComponents}</div>
      )}
    </div>
  );
}
