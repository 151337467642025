import { Weekday } from "common/types.ts";
import { WEEK_DAYS } from "@/src/constants.tsx";
import { ToggleButton, ToggleButtonGroup } from "react-aria-components";
import { clsx } from "clsx";
import { buttonStyles } from "common/components/base";

export function WeekdaySelector({
  selectedWeekdays,
  setSelectedWeekdays,
}: {
  selectedWeekdays: Weekday[] | undefined;
  setSelectedWeekdays: (selectedWeekdays: Weekday[]) => void;
}) {
  return (
    <ToggleButtonGroup
      className="flex gap-x-1.5"
      disallowEmptySelection={true}
      selectionMode="multiple"
      selectedKeys={selectedWeekdays}
      onSelectionChange={(selection) => {
        setSelectedWeekdays(Array.from(selection) as Weekday[]);
      }}
    >
      {WEEK_DAYS.map((weekDay) => (
        <ToggleButton
          key={weekDay}
          id={weekDay}
          className={({ isSelected }) =>
            clsx(
              "flex-1 capitalize",
              buttonStyles({
                size: "md",
                variant: isSelected ? "black" : "stroke",
                className: "px-0",
              }),
            )
          }
        >
          <span className="-mx-2">{weekDay.slice(0, 3)}</span>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
