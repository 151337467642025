import { Pagination, Skeleton } from "common/components/base";

import ArrowUpRightAndArrowDownLeftFromCenter from "fontawesome/regular/arrow-up-right-and-arrow-down-left-from-center.svg?react";
import NoDrivesImage from "@/src/assets/empty-placeholder/no-drives.svg?react";

import type { Selection } from "react-aria-components";
import { useTwBreakpoint } from "common/hooks";
import useDrives from "../datahooks/useDrives.tsx";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import EmptyPlaceholder from "common/components/base/EmptyPlaceholder/EmptyPlaceholder.tsx";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { addLeadingZero, getWeekDay } from "common/utils.ts";
import { ListBox, ListBoxItem } from "react-aria-components";
import { useState } from "react";
import DriveDetails from "@/src/pages/drives/components/DriveDetails.tsx";

const listBoxClassName =
  "mb-6 grid grid-cols-1 gap-2 md:grid-cols-2 lg:mb-20 lg:grid-cols-3 xl:grid-cols-4";
const listBoxItemClassName =
  "group flex items-center rounded-3xl border border-grey-300 pr-3 data-[focused]:border-grey-400 data-[focused]:bg-grey-50 data-[hovered]:bg-grey-50 data-[hovered]:shadow-lg data-[focused]:outline-none lg:pr-4";
const driveImageClassName =
  "mr-3 size-16 rounded-3xl bg-grey-200 lg:mr-4 lg:size-20";

export default function AllDrives() {
  const isDesktop = useTwBreakpoint("lg");
  const { placeId } = useParams({ from: "/$placeId" });
  const { date, page } = useSearch({
    from: "/$placeId/_layout/drives",
  });
  const pageSize = isDesktop ? 20 : 8;
  const navigate = useNavigate({ from: "/$placeId/drives" });
  const { drives, pageCount, isLoadingDrives } = useDrives(placeId, {
    date,
    page,
    pageSize,
  });
  const [selectedDriveId, setSelectedDriveId] = useState<Selection>(new Set());

  if (drives && !drives.length) {
    return (
      <EmptyPlaceholder
        title="No Drives within this Timeframe"
        subtitle="You haven't received any Drives within the selected timeframe. Please use the Date picker to select another date!"
        image={<NoDrivesImage />}
      />
    );
  }
  const selectedDrive =
    drives && selectedDriveId !== "all"
      ? drives.find(({ id }) => id === selectedDriveId.values().next().value)
      : undefined;

  return (
    <div className="flex grow flex-col rounded-4xl bg-white p-3 pb-4 lg:rounded-7xl lg:p-6">
      {isLoadingDrives ? (
        <div className={listBoxClassName}>
          {new Array(pageSize).fill(null).map(() => (
            <div className={listBoxItemClassName}>
              <Skeleton className={driveImageClassName}></Skeleton>
              <div className="flex flex-col gap-y-0.5 py-2">
                <Skeleton className="h-5 w-32 rounded-full" />
                <Skeleton className="h-7 w-24 rounded-full" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ListBox
          selectedKeys={selectedDriveId}
          onSelectionChange={(selection) => {
            setSelectedDriveId(selection);
          }}
          items={drives}
          className={listBoxClassName}
          layout="grid"
          selectionMode="single"
          aria-label="all drives"
        >
          {(drive) => {
            const { id, image_url, created_at } = drive;
            const dateTime = fromAbsolute(created_at, getLocalTimeZone());

            return (
              <ListBoxItem className={listBoxItemClassName}>
                {({ isHovered, isFocused }) => {
                  return (
                    <>
                      <img
                        className={driveImageClassName}
                        src={image_url}
                        alt=""
                      />
                      <div className="flex flex-1 flex-col gap-y-0.5 overflow-hidden py-2">
                        <span className="truncate text-left text-sm font-normal capitalize text-grey-500">
                          {getWeekDay(dateTime).slice(0, 3)} {dateTime.day} |{" "}
                          {addLeadingZero(dateTime.hour)}:
                          {addLeadingZero(dateTime.minute)}
                        </span>
                        <span className="truncate text-left text-base font-semibold lg:text-xl">
                          Drive {id}
                        </span>
                      </div>
                      {(isHovered || isFocused) && (
                        <ArrowUpRightAndArrowDownLeftFromCenter className="m-4 size-4" />
                      )}
                    </>
                  );
                }}
              </ListBoxItem>
            );
          }}
        </ListBox>
      )}
      {pageCount && (
        <Pagination
          className="mx-auto mt-auto"
          size="md"
          pagesCount={pageCount}
          pageIndex={page}
          setPageIndex={(pageIndex) => {
            navigate({
              search: (prevSearch) => ({ ...prevSearch, page: pageIndex }),
            });
          }}
        />
      )}
      <DriveDetails
        drive={selectedDrive}
        onClose={() => setSelectedDriveId(new Set())}
      />
    </div>
  );
}
