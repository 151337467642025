import type { LabelProps as AriaLabelProps } from "react-aria-components";
import { ForwardedRef, forwardRef, JSX } from "react";
import { tv, VariantProps } from "tailwind-variants";
import { Button, LabelContext, useContextProps } from "react-aria-components";
import { Tooltip, TooltipTrigger } from "common/components/base";

import CircleInfo from "fontawesome/solid/circle-info.svg?react";

const labelStyle = tv({
  base: "font-medium text-grey-700 mb-1 ml-1 flex items-center",
  variants: {
    size: {
      sm: "text-2xs gap-x-0.5 [&_svg]:size-2.5",
      md: "text-xs gap-x-0.5 [&_svg]:size-3",
      lg: "text-sm gap-x-1 [&_svg]:size-3.5",
    },
  },
});
export interface LabelProps
  extends Omit<AriaLabelProps, "className">,
    Required<VariantProps<typeof labelStyle>> {
  tooltipContent?: string;
  tooltipIcon?: JSX.Element;
}
export const Label = forwardRef(
  (props: LabelProps, ref: ForwardedRef<HTMLLabelElement>) => {
    [props, ref] = useContextProps(props, ref, LabelContext);

    const { size, tooltipContent, tooltipIcon, ...rest } = props;

    return (
      <div className={labelStyle({ size })}>
        <label {...rest} ref={ref} />
        {tooltipContent && (
          <TooltipTrigger>
            <Button>
              {tooltipIcon || <CircleInfo className="fill-grey-500" />}
            </Button>
            <Tooltip variant="black">
              <span className="text-xs text-grey-500">{tooltipContent}</span>
            </Tooltip>
          </TooltipTrigger>
        )}
      </div>
    );
  },
);
