import { Button } from "common/components/base";
import { createFileRoute } from "@tanstack/react-router";

import { useAppStore, usePlaceSelection, useTwBreakpoint } from "common/hooks";
import usePlaces from "@/src/common/datahooks/usePlaces.ts";
import { useState } from "react";
import ProfilesTable from "@/src/pages/profiles/ProfilesTable.tsx";
import ProfilesList from "@/src/pages/profiles/ProfilesList.tsx";
import { SearchField } from "../../common/components/base/SearchField";

export const Route = createFileRoute("/_layout/profiles")({
  component: Profiles,
});
function Profiles() {
  const isDesktop = useTwBreakpoint("lg");
  const [searchText, setSearchText] = useState<string>();
  const { places } = usePlaces();
  const { selectPlace } = usePlaceSelection();

  const filteredPlaces = places
    ? places.filter(({ name }) => {
        return searchText
          ? name.toLowerCase().includes(searchText.toLowerCase())
          : true;
      })
    : [];

  const titleComponent = (
    <h1 className="text-lg font-semibold lg:text-xl">GBP profiles</h1>
  );
  const subtitleComponent = (
    <p className="mb-2 text-sm font-medium text-grey-500 lg:mb-3">
      Manage your Google Business Profiles here
    </p>
  );
  const addProfileComponent = (
    <Button
      variant="black"
      size="md"
      onPress={() => useAppStore.getState().setIsAddModalOpen(true)}
    >
      Add new GBP profile
    </Button>
  );
  const inputComponent = (
    <SearchField
      size="lg"
      placeholder="Search GBP"
      value={searchText}
      className="w-full lg:w-80"
      onChange={(value) => setSearchText(value)}
    />
  );
  return (
    <div className="flex grow flex-col rounded-4xl bg-grey-50 lg:rounded-7xl">
      {isDesktop ? (
        <div className="flex flex-col gap-y-3 p-6 pb-5">
          <div className="flex flex-col gap-y-1">
            {titleComponent}
            {subtitleComponent}
          </div>
          <div className="flex justify-between">
            {inputComponent}
            {addProfileComponent}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-y-2 p-3">
          <div className="flex items-center justify-between">
            {titleComponent}
            {addProfileComponent}
          </div>
          {subtitleComponent}
          {inputComponent}
        </div>
      )}
      <div className="flex grow flex-col rounded-4xl bg-white px-2 py-3 lg:rounded-7xl lg:px-3 lg:pb-4 lg:pt-6">
        <ProfilesTable places={filteredPlaces} goToPlace={selectPlace} />
        <ProfilesList places={filteredPlaces} goToPlace={selectPlace} />
      </div>
    </div>
  );
}
