import { useQuery } from "@tanstack/react-query";

import { get } from "../helpers/HTTP";

async function getBraintreeToken() {
  return (await get(`v1/billing/token`)).token;
}

export default function useBraintreeToken() {
  const { data: braintreeToken, isLoading: isLoadingBraintreeToken } = useQuery(
    { queryKey: ["braintreeToken"], queryFn: getBraintreeToken },
  );

  return { braintreeToken, isLoadingBraintreeToken };
}
