import { tv, VariantProps } from "tailwind-variants";
import XMark from "fontawesome/regular/xmark.svg?react";
import CircleExcalamation from "fontawesome/solid/circle-exclamation.svg?react";
import CircleCheck from "fontawesome/solid/circle-check.svg?react";
import CircleInfo from "fontawesome/solid/circle-info.svg?react";
import { clsx } from "clsx";
import { UnstyledButton } from "common/components/base";

const alertStyles = tv({
  base: "flex bg-white border gap-x-3 p-4 shadow-lg rounded-3xl items-start",
  variants: {
    variant: {
      default: "border-grey-200",
      warning: "border-yellow-300",
      error: "border-red-400",
      success: "border-green-400",
      purple: "border-purple-500 bg-purple-500/10 [&>svg]:fill-purple-500",
    },
  },
});
const icons = {
  default: CircleInfo,
  warning: CircleExcalamation,
  error: CircleExcalamation,
  success: CircleCheck,
  purple: CircleInfo,
};
export interface AlertProps
  extends Required<Pick<VariantProps<typeof alertStyles>, "variant">>,
    Omit<VariantProps<typeof alertStyles>, "variant"> {
  className?: string;
  title?: string;
  text: string;
  onClose?: () => void;
}
export function Alert({
  className,
  title,
  text,
  variant,
  onClose,
}: AlertProps) {
  const Icon = icons[variant];

  return (
    <div className={alertStyles({ variant, className })}>
      <Icon
        className={clsx("size-5", variant === "purple" && "fill-purple-500")}
      />
      <div className="flex flex-col gap-y-1">
        {title && <span className="font-medium">{title}</span>}
        <p className="text-sm text-grey-700">{text}</p>
      </div>
      {onClose && (
        <UnstyledButton onPress={onClose}>
          <XMark className="size-4 fill-grey-500" />
        </UnstyledButton>
      )}
    </div>
  );
}
