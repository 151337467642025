import { Badge, Button, DialogHeading } from "common/components/base";
import { SelectPaymentMethod } from "../SelectPaymentMethod";
import Check from "fontawesome/solid/check.svg?react";
import { useState } from "react";
import GradientBackground from "@/src/assets/create-trial-bg.svg?react";
import usePlanPricing from "common/datahooks/usePlanPricing.tsx";

const planItems = [
  { label: "Grid view", value: "5x5" },
  { label: "Daily drives", value: "10" },
  { label: "Grid update frequency", value: "every day" },
  { label: "Target keywords", value: "3" },
];
export default function PaymentDetails({
  onConfirm,
  isLoading,
}: {
  onConfirm: (paymentSourceId: string) => void;
  isLoading: boolean;
}) {
  const { planPricing } = usePlanPricing();
  const [selectedPaymentSourceId, setSelectedPaymentSourceId] =
    useState<string>();

  return (
    <div className="flex lg:h-[536px]">
      <div className="flex flex-1 flex-col pr-6">
        <DialogHeading className="mb-3 text-2xl font-semibold">
          Payment details
        </DialogHeading>
        <SelectPaymentMethod
          selectedPaymentSourceId={selectedPaymentSourceId}
          setSelectedPaymentSourceId={setSelectedPaymentSourceId}
          footerContent={
            <Button
              onPress={() => {
                if (selectedPaymentSourceId) {
                  onConfirm(selectedPaymentSourceId);
                }
              }}
              className="ml-auto mt-auto"
              size="md"
              variant="black"
              isDisabled={!selectedPaymentSourceId}
              isLoading={isLoading}
            >
              Start free trial
            </Button>
          }
        />
      </div>
      <div className="relative -my-6 -mr-6 flex flex-1 flex-col justify-center overflow-hidden rounded-5xl p-6 pt-16">
        <GradientBackground className="absolute inset-0 size-full" />
        <div className="z-base mb-6 flex flex-col items-start rounded-3xl border border-grey-300 bg-grey-50 px-5 py-6 shadow-[0px_4px_0px_1px_#CED8E9]">
          <Badge variant="purple" size="md" className="mb-3">
            7-day free trial
          </Badge>
          <p className="mb-3.5 text-sm text-grey-500">
            An optimal way to start growing your Google Business Profile
            effortlessly. Perfect for small businesses
          </p>
          <div className="mb-8 flex flex-col gap-y-4">
            {planItems.map(({ label, value }) => (
              <div className="flex items-center gap-x-2 text-sm" key={label}>
                <Check className="size-4 fill-purple-400" />
                <span>
                  {label}: <span className="font-semibold">{value}</span>
                </span>
              </div>
            ))}
          </div>
          <div className="flex w-full flex-col gap-y-1">
            <div className="flex items-center justify-between">
              <span className="text-sm text-grey-500">Billed:</span>
              <span className="text-2xl font-semibold text-purple-400">
                $0 today
              </span>
            </div>
            {planPricing && (
              <span className="text-right text-xs text-grey-500">
                ${planPricing.month / 100}/month billed after 7-day free trial
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
