import { useQuery } from "@tanstack/react-query";
import {
  BillingFrequency,
  PaymentEstimate,
  PlanSettings,
} from "common/types.ts";
import { post } from "@/src/common/helpers/HTTP.ts";

async function getPaymentEstimateRequest(
  placeId: string,
  billingFrequency: BillingFrequency,
  planSettings: PlanSettings,
): Promise<PaymentEstimate> {
  const { estimate } = await post(`v1/places/${placeId}/update-estimate`, {
    billing_frequency: billingFrequency,
    ...planSettings,
    keywords: planSettings.keyword_count,
    daily_drives: planSettings.drives,
  });
  return {
    ...estimate,
    keyword_count: estimate.keywords,
    drives: estimate.daily_drives,
    schedule: estimate.frequency,
  };
}
//this returns the next payment estimate for the provided plan and billing frequency
export default function usePaymentEstimate(
  placeId: string,
  billingFrequency: BillingFrequency,
  planSettings: PlanSettings | undefined,
) {
  const { data: paymentEstimate, isLoading: isLoadingPaymentEstimate } =
    useQuery({
      queryKey: ["paymentEstimate", placeId, billingFrequency, planSettings],
      queryFn: planSettings
        ? () =>
            getPaymentEstimateRequest(placeId, billingFrequency, planSettings)
        : undefined,
      enabled: !!planSettings,
    });
  return { paymentEstimate, isLoadingPaymentEstimate };
}
