import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import { DropdownBox, DropdownBoxItem, Select } from "common/components/base";
import useCustomerMutations from "common/datahooks/useCustomerMutations.tsx";
import { FormEvent } from "react";
import useCustomer from "common/datahooks/useCustomer.ts";

const unitValues = [{ label: "Meters" }, { label: "Feet" }] as const;
export default function Units({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  const { customer } = useCustomer();
  const { updateCustomer, isUpdatingCustomer } = useCustomerMutations();

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    const formData = new FormData(e.currentTarget);
    const unit = formData
      .get("unit")
      ?.toString() as (typeof unitValues)[number]["label"];

    if (unit) {
      updateCustomer({ preferred_unit: unit }).then(toggleIsEditing);
    }
  }

  return (
    <SettingsItem
      label="Units"
      onSubmit={onSubmit}
      isEditing={isEditing}
      isLoading={isUpdatingCustomer}
      toggleIsEditing={toggleIsEditing}
      editingComponent={
        <Select
          size="md"
          className="w-full lg:max-w-40"
          defaultSelectedKey={customer?.preferred_unit}
          name="unit"
          aria-label="unit"
        >
          <DropdownBox size="sm" items={unitValues}>
            {({ label }) => (
              <DropdownBoxItem size="sm" id={label} title={label} key={label} />
            )}
          </DropdownBox>
        </Select>
      }
    >
      {customer ? (
        <span className="text-sm font-medium">{customer.preferred_unit}</span>
      ) : (
        <span className="text-sm">Loading...</span>
      )}
    </SettingsItem>
  );
}
