import {
  Button,
  DialogHeading,
  MobileSheet,
  Modal,
} from "common/components/base";
import { DialogTrigger } from "react-aria-components";
import useSubscriptionMutations from "common/datahooks/useSubscriptionMutations.tsx";
import { useParams } from "@tanstack/react-router";
import { useTwBreakpoint } from "common/hooks";

export default function CancelSubscription() {
  const isDesktop = useTwBreakpoint("lg");
  const { placeId } = useParams({ from: "/$placeId" });
  const { cancelSubscription, isCancellingSubscription } =
    useSubscriptionMutations();

  function onCancel(closeDialog: () => void) {
    cancelSubscription({ placeId }).then(() => {
      closeDialog();
    });
  }

  const Component = isDesktop ? Modal : MobileSheet;

  return (
    <DialogTrigger>
      <Button variant="stroke" size="sm">
        Cancel subscription
      </Button>
      <Component variant="white" className="w-[510px] flex-col">
        {({ close }) => (
          <>
            <DialogHeading className="mb-2 mt-10 text-center text-2xl font-semibold">
              Are you sure you want to cancel your subscription?
            </DialogHeading>
            <p className="mb-6 text-center text-grey-500">
              By canceling your subscription now, you will immediately lose
              access to your plan features and benefits. This action takes
              effect right away and cannot be undone
            </p>
            <div className="flex gap-x-3">
              <Button
                onPress={close}
                variant="white"
                size="md"
                className="flex-1"
                isDisabled={isCancellingSubscription}
              >
                Go back
              </Button>
              <Button
                variant="black"
                size="md"
                className="flex-1"
                onPress={() => onCancel(close)}
                isLoading={isCancellingSubscription}
              >
                Yes, cancel
              </Button>
            </div>
          </>
        )}
      </Component>
    </DialogTrigger>
  );
}
