import { Button, DialogHeading } from "common/components/base";
import { SelectPaymentMethod } from "common/components/SelectPaymentMethod";
import { useState } from "react";
import { useTwBreakpoint } from "common/hooks";

export default function UpdatePaymentMethod({
  onClose,
  setPaymentSource,
}: {
  onClose: () => void;
  setPaymentSource: (paymentSourceId: string) => void;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const [selectedPaymentSourceId, setSelectedPaymentSourceId] =
    useState<string>();

  function onSave() {
    if (selectedPaymentSourceId) {
      setPaymentSource(selectedPaymentSourceId);
    }
  }

  return (
    <>
      <DialogHeading className="mb-4 text-2xl font-medium max-lg:mx-auto max-lg:py-1.5 lg:mb-6 lg:text-xl lg:font-semibold">
        Payment details
      </DialogHeading>
      <SelectPaymentMethod
        selectedPaymentSourceId={selectedPaymentSourceId}
        setSelectedPaymentSourceId={setSelectedPaymentSourceId}
        footerContent={
          <div className="flex gap-x-3">
            <Button
              onPress={onClose}
              variant="white"
              className="flex-1"
              size={isDesktop ? "md" : "lg"}
            >
              Cancel
            </Button>
            <Button
              className="flex-1"
              onPress={onSave}
              variant="black"
              size={isDesktop ? "md" : "lg"}
              isDisabled={!selectedPaymentSourceId}
            >
              Save
            </Button>
          </div>
        }
      />
    </>
  );
}
