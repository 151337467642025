import { useEffect, useState } from "react";
import CircleNotch from "fontawesome/regular/spinner.svg?react";

const paypalSpinnerId = "paypal-spinner";
export default function PaypalButton({
  onTokenReceived,
  braintreeClient,
}: {
  onTokenReceived: (token: string) => void;
  braintreeClient: BraintreeClient | undefined;
}) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (braintreeClient) {
      braintree.paypalCheckout
        .create({
          client: braintreeClient,
        })
        .then((paypalCheckoutInstance) =>
          paypalCheckoutInstance.loadPayPalSDK({
            vault: true,
          }),
        )
        .then((paypalCheckoutInstance) => {
          document.getElementById(paypalSpinnerId)?.remove();
          paypal
            .Buttons({
              fundingSource: paypal.FUNDING.PAYPAL as unknown as string,
              createBillingAgreement() {
                return paypalCheckoutInstance.createPayment({
                  flow: "vault",
                  enableShippingAddress: false,
                });
              },
              async onApprove(data: unknown) {
                const payload =
                  await paypalCheckoutInstance.tokenizePayment(data);
                onTokenReceived(payload.nonce);
                return payload;
              },
              onCancel() {},
              onError() {
                // console.error("PayPal error", err);
              },
            })
            .render("#paypal-button");
        })
        .then(() => {
          // The PayPal button will be rendered in an html element with the ID
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, [braintreeClient]);

  if (isError) {
    return (
      <p className="my-16 text-center text-red-500">
        Error creating paypal component
      </p>
    );
  }

  return (
    <div
      className="my-auto flex h-[45px] w-full items-center justify-center rounded bg-[#ffc439]"
      id="paypal-button"
    >
      <CircleNotch id={paypalSpinnerId} className="size-5 animate-spin" />
    </div>
  );
}
