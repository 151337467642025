import { useMutation, useQueryClient } from "@tanstack/react-query";
import { del, post } from "@/src/common/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/common/helpers/getQueryKeys.ts";
import { produce } from "immer";
import { DetailedPlace, Keyword } from "common/types.ts";
import { useParams } from "@tanstack/react-router";

async function removeKeywordRequest(
  placeId: string,
  keywordId: string,
): Promise<Keyword> {
  const { keyword } = await del(`v1/places/${placeId}/keywords/${keywordId}`);
  return keyword;
}
async function addKeywordRequest(
  placeId: string,
  keywordData: { id: string } | { label: string },
): Promise<Keyword> {
  const { keyword } = await post(`v1/places/${placeId}/keywords`, keywordData);
  return keyword;
}

export default function useKeywordsMutations() {
  const { placeId } = useParams({ from: "/$placeId" });
  const queryClient = useQueryClient();

  const { mutateAsync: removeKeyword, isPending: isRemovingKeyword } =
    useMutation({
      mutationFn: ({ keywordId }: { keywordId: string }) =>
        removeKeywordRequest(placeId, keywordId),
      onSuccess: (keyword, { keywordId }) => {
        queryClient.setQueryData<Keyword[]>(
          getQueryKeys().keywords.suggestions(placeId),
          (suggestions) => {
            if (suggestions) {
              return produce(suggestions, (draftSuggestions) => {
                draftSuggestions.push(keyword);
              });
            }
          },
        );
        queryClient.setQueryData<DetailedPlace>(
          getQueryKeys().places.details(placeId),
          (detailedPlace) => {
            if (detailedPlace) {
              return produce(detailedPlace, (draftDetailedPlace) => {
                draftDetailedPlace.keywords =
                  draftDetailedPlace.keywords.filter(
                    ({ id }) => id !== keywordId,
                  );
              });
            }
          },
        );
      },
    });

  const { mutateAsync: addKeyword, isPending: isAddingKeyword } = useMutation({
    mutationFn: (keywordData: { id: string } | { label: string }) =>
      addKeywordRequest(placeId, keywordData),
    onSuccess: (keyword) => {
      queryClient.setQueryData<DetailedPlace>(
        getQueryKeys().places.details(placeId),
        (detailedPlace) => {
          if (detailedPlace) {
            return produce(detailedPlace, (draftDetailedPlace) => {
              draftDetailedPlace.keywords.push(keyword);
            });
          }
        },
      );
      queryClient.setQueryData<Keyword[]>(
        getQueryKeys().keywords.suggestions(placeId),
        (keywordSuggestions) => {
          if (keywordSuggestions) {
            return keywordSuggestions.filter(({ id }) => id !== keyword.id);
          }
        },
      );
    },
  });

  return {
    removeKeyword,
    isRemovingKeyword,
    addKeyword,
    isAddingKeyword,
  };
}
