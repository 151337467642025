import { cloneElement, ReactNode, useRef, useState, JSX } from "react";
import { DialogTrigger } from "react-aria-components";
import { Popover } from "common/components/base";

export default function Tooltip({
  children,
  buttonComponent,
}: {
  children: ReactNode;
  buttonComponent: JSX.Element;
}) {
  const timeoutRef = useRef<number>();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DialogTrigger isOpen={isOpen}>
      {cloneElement(buttonComponent, {
        onHoverStart: () => {
          timeoutRef.current = window.setTimeout(() => setIsOpen(true), 400);
        },
        onHoverEnd: () => {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = window.setTimeout(() => setIsOpen(false), 100);
        },
      })}
      <Popover
        shouldFlip={false}
        placement="bottom"
        containerPadding={-Infinity}
        isNonModal={true}
      >
        <div
          onMouseEnter={() => {
            clearTimeout(timeoutRef.current);
          }}
          onMouseLeave={() => setIsOpen(false)}
          className="flex flex-col gap-y-2.5 px-4 pb-5 pt-3.5"
        >
          {children}
        </div>
      </Popover>
    </DialogTrigger>
  );
}
