import { Snapshot } from "common/types.ts";
import { useTwBreakpoint } from "common/hooks";

import DateSelectorButton from "@/src/pages/home/DateSelector/DateSelectorButton.tsx";
import { useSearch } from "@tanstack/react-router";
import { Button, Skeleton } from "common/components/base";

import ChevronRight from "fontawesome/regular/chevron-right.svg?react";
import ChevronLeft from "fontawesome/regular/chevron-left.svg?react";
import { useEffect, useRef, useState } from "react";

export default function DateSelector({
  snapshots,
  loadMore,
  isLoading,
}: {
  snapshots: Snapshot[] | undefined;
  loadMore: () => void;
  isLoading: boolean;
}) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const isDesktop = useTwBreakpoint("lg");
  const { selectedDate } = useSearch({
    from: "/$placeId/_layout/home",
  });
  const [page, setPage] = useState(0);

  let transformValue = 0;
  //this is used to stop the loop when the current page is reached
  let pagesCount = 0;
  let hasMore = true;

  if (scrollContainerRef.current && buttonContainerRef.current && snapshots) {
    const boxWidth = scrollContainerRef.current.clientWidth;
    const containerBoundingRect =
      buttonContainerRef.current.getBoundingClientRect();

    for (let i = 0; i < buttonContainerRef.current.children.length; i++) {
      const buttonBoundingRect =
        buttonContainerRef.current.children[i].getBoundingClientRect();
      const rightFromParent =
        containerBoundingRect.right - buttonBoundingRect.right;
      const rightWithOffset = rightFromParent - transformValue;

      //check if the button is fully visible or not. if it's not fully visible it will be the first element in the next page (transformValue = rightFromParent)
      if (
        (rightWithOffset < boxWidth &&
          rightWithOffset + buttonBoundingRect.width > boxWidth) ||
        rightWithOffset > boxWidth
      ) {
        pagesCount += 1;
        if (pagesCount <= page) {
          transformValue = rightFromParent;
        }
      }
      if (
        pagesCount <= page &&
        i === snapshots.length - 1 &&
        rightWithOffset % boxWidth >= 0 &&
        rightWithOffset + buttonBoundingRect.width <= boxWidth * (page + 1)
      ) {
        hasMore = false;
      }
    }
  }

  useEffect(() => {
    if (!hasMore) {
      loadMore();
    }
  }, [hasMore, loadMore]);

  const loadingComponents = new Array(10)
    .fill(null)
    .map((_, index) => (
      <Skeleton
        key={index}
        className="h-14 w-11 shrink-0 rounded-3xl lg:h-8 lg:w-32 lg:rounded-full"
      />
    ));

  return (
    <div className="flex items-center">
      {snapshots && !snapshots.length ? (
        <span className="mx-auto my-2.5 text-grey-500">Nothing here</span>
      ) : (
        <>
          <Button
            variant="transparent"
            size={isDesktop ? "md" : "sm"}
            isIconOnly
            isDisabled={!hasMore}
            onPress={() => setPage(page + 1)}
          >
            <ChevronLeft />
          </Button>
          <div
            className="flex grow flex-row-reverse justify-start overflow-hidden"
            ref={scrollContainerRef}
          >
            <div
              className="flex flex-row-reverse justify-start gap-x-1.5 transition-transform duration-150 lg:gap-x-5"
              style={{
                transform: `translateX(${transformValue}px)`,
              }}
              ref={buttonContainerRef}
            >
              {snapshots
                ? snapshots.map((snapshot, index) => {
                    const selectedSnapshot = snapshots.find(
                      (snapshot) => snapshot.date === selectedDate,
                    );

                    return (
                      <DateSelectorButton
                        key={snapshot.date}
                        snapshot={snapshot}
                        prevSnapshot={snapshots[index + 1]}
                        selectedSnapshot={selectedSnapshot}
                      />
                    );
                  })
                : loadingComponents}
              {isLoading && loadingComponents}
            </div>
          </div>
          <Button
            variant="transparent"
            size={isDesktop ? "md" : "sm"}
            isIconOnly
            isDisabled={page === 0}
            onPress={() => setPage(page - 1)}
          >
            <ChevronRight />
          </Button>
        </>
      )}
    </div>
  );
}
