import { ReactNode, useEffect, useState } from "react";
import usePaymentSources from "common/datahooks/usePaymentSources.ts";
import { buttonStyles, UnstyledButton } from "common/components/base";
import Plus from "fontawesome/regular/plus.svg?react";

import AddPaymentMethod from "common/components/SelectPaymentMethod/AddPaymentMethod.tsx";
import PaymentSourcesList from "common/components/SelectPaymentMethod/PaymentSourcesList.tsx";

export function SelectPaymentMethod({
  selectedPaymentSourceId,
  setSelectedPaymentSourceId,
  footerContent,
}: {
  selectedPaymentSourceId: string | undefined;
  setSelectedPaymentSourceId: (paymentSourceId: string) => void;
  footerContent: ReactNode;
}) {
  const [isAdding, setIsAdding] = useState(false);
  const { paymentSources, isLoadingPaymentSources } = usePaymentSources();

  useEffect(() => {
    window.Chargebee.init({
      site: import.meta.env.VITE_CHARGEBEE_SITE,
      publishableKey: import.meta.env.VITE_CHARGEBEE_API_KEY,
    });
  }, []);

  useEffect(() => {
    if (paymentSources && !paymentSources.length) {
      setIsAdding(true);
    }
  }, [paymentSources]);

  return (
    <div className="flex flex-1 flex-col overflow-hidden md:min-w-[400px]">
      {isAdding ? (
        <AddPaymentMethod
          goBack={(paymentSourceId) => {
            if (paymentSourceId) {
              setSelectedPaymentSourceId(paymentSourceId);
            }
            setIsAdding(false);
          }}
        />
      ) : (
        <>
          <div className="mb-3 flex flex-1 flex-col gap-y-3 overflow-hidden">
            <div className="flex flex-col gap-y-3 overflow-y-auto">
              <PaymentSourcesList
                paymentSources={paymentSources}
                setSelectedPaymentSourceId={setSelectedPaymentSourceId}
                selectedPaymentSourceId={selectedPaymentSourceId}
              />
            </div>
            {!isLoadingPaymentSources && !!paymentSources && (
              <UnstyledButton
                onPress={() => setIsAdding(true)}
                className="flex items-center gap-x-3 rounded-5xl border border-dashed border-grey-300 p-3 text-sm font-semibold data-[hovered]:bg-grey-50 md:px-3.5"
              >
                <div
                  className={buttonStyles({
                    variant: "white",
                    size: "md",
                    isIconOnly: true,
                  })}
                >
                  <Plus />
                </div>
                Add new payment method
              </UnstyledButton>
            )}
          </div>
          {!isAdding && footerContent}
        </>
      )}
    </div>
  );
}
