import { ToggleButton, ToggleButtonGroup } from "react-aria-components";

import { ReactNode } from "react";
import { clsx } from "clsx";

export function ButtonGroup<T extends string>({
  items,
  selectedKey,
  setSelectedKey,
  className,
}: {
  items: readonly { key: T; content: ReactNode }[];
  selectedKey: T;
  setSelectedKey: (key: T) => void;
  className?: string;
}) {
  const selectedIndex = items.findIndex(({ key }) => key === selectedKey);
  return (
    <ToggleButtonGroup
      className={clsx(
        "relative z-base flex w-full gap-x-1.5 rounded-full bg-grey-50 p-[3px]",
        className,
      )}
      disallowEmptySelection
      onSelectionChange={(selection) => {
        setSelectedKey(Array.from(selection)[0] as T);
      }}
      selectedKeys={new Set([selectedKey])}
      selectionMode="single"
    >
      <div
        className={clsx(
          "shadow-s absolute z-[-10] h-11 rounded-full bg-white transition-[transform]",
        )}
        style={{
          width: `calc(${100 / items.length}% - 6px)`,
          transform: `translateX(calc(${100 * selectedIndex}% + ${6 * selectedIndex}px))`,
        }}
      />
      {items.map(({ key, content }) => {
        return (
          <ToggleButton
            key={key}
            id={key}
            className={clsx(
              "flex h-11 flex-1 items-center justify-center text-nowrap rounded-full text-sm font-medium",
            )}
          >
            {content}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
