import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import { Input, FieldError } from "common/components/base";
import { TextField } from "react-aria-components";
import useCustomerMutations from "common/datahooks/useCustomerMutations.tsx";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { PasswordSchema, PasswordSchemaType } from "common/schemas.tsx";
import { valibotResolver } from "@hookform/resolvers/valibot";

export default function Password({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  const { updateCustomer, isUpdatingCustomer } = useCustomerMutations();
  const { control, handleSubmit, reset } = useForm<PasswordSchemaType>({
    resolver: valibotResolver(PasswordSchema),
  });

  useEffect(() => {
    reset();
  }, [isEditing]);

  function onSubmit({ password }: PasswordSchemaType) {
    updateCustomer({ password }).then(toggleIsEditing);
  }

  return (
    <SettingsItem
      label="Password"
      onSubmit={handleSubmit(onSubmit)}
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      isLoading={isUpdatingCustomer}
      editingComponent={
        <Controller
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error },
          }) => (
            <TextField
              aria-label="password"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              validationBehavior="aria"
              isInvalid={invalid}
            >
              <Input
                ref={ref}
                size="md"
                placeholder="Password"
                className="w-full lg:max-w-40 xl:max-w-72"
                autoFocus
              />
              <FieldError size="md">{error?.message}</FieldError>
            </TextField>
          )}
          name="password"
        />
      }
    >
      <span className="text-sm font-medium">●●●●●●●●</span>
    </SettingsItem>
  );
}
