import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patch, post } from "@/src/common/helpers/HTTP.ts";
import {
  BillingFrequency,
  DetailedPlace,
  DetailedSubscription,
  PlanSettings,
} from "common/types.ts";
import { produce } from "immer";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";

async function cancelSubscriptionRequest(
  placeId: string,
): Promise<DetailedSubscription> {
  return (await post(`v1/places/${placeId}/cancel`)).subscription;
}
async function updateSubscriptionPlanRequest(
  placeId: string,
  billingFrequency: BillingFrequency,
  planSettings: PlanSettings,
  paymentSourceId: string,
) {
  await patch(`v1/places/${placeId}`, {
    billing_frequency: billingFrequency,
    ...planSettings,
    keywords: planSettings.keyword_count,
    daily_drives: planSettings.drives,
    payment_source_id: paymentSourceId,
  });
}

async function updateSubscriptionPaymentSourceRequest(
  placeId: string,
  paymentSourceId: string,
) {
  await patch(`v1/places/${placeId}/payment-source`, {
    payment_source_id: paymentSourceId,
  });
}
export default function useSubscriptionMutations() {
  const queryClient = useQueryClient();

  const {
    mutateAsync: cancelSubscription,
    isPending: isCancellingSubscription,
  } = useMutation({
    mutationFn: ({ placeId }: { placeId: string }) =>
      cancelSubscriptionRequest(placeId),
    onSuccess: ({ cancelled_at }, { placeId }) => {
      queryClient.setQueryData<DetailedSubscription>(
        ["subscription", placeId],
        (subscription) => {
          if (subscription) {
            return produce(subscription, (draftSubscription) => {
              draftSubscription.status = "cancelled";
              draftSubscription.cancelled_at = cancelled_at;
            });
          }
        },
      );
    },
  });
  const {
    mutateAsync: updateSubscriptionPlan,
    isPending: isUpdatingSubscriptionPlan,
    isSuccess: isUpdatingSubscriptionPlanSuccess,
    reset: resetUpdateSubscriptionPlan,
  } = useMutation({
    mutationFn: ({
      placeId,
      billingFrequency,
      planSettings,
      paymentSourceId,
    }: {
      placeId: string;
      billingFrequency: BillingFrequency;
      planSettings: PlanSettings;
      paymentSourceId: string;
    }) =>
      updateSubscriptionPlanRequest(
        placeId,
        billingFrequency,
        planSettings,
        paymentSourceId,
      ),
    onSuccess: (_, { placeId, paymentSourceId, planSettings }) => {
      queryClient.setQueryData<DetailedSubscription>(
        ["subscription", placeId],
        (subscription) => {
          if (subscription) {
            return produce(subscription, (draftSubscription) => {
              draftSubscription.payment_source_id = paymentSourceId;
              draftSubscription.status = "active";
            });
          }
        },
      );
      queryClient.setQueryData<DetailedPlace>(
        getQueryKeys().places.details(placeId),
        (place) => {
          if (place) {
            return {
              ...place,
              settings: { ...place.settings, ...planSettings },
            };
          }
        },
      );
    },
  });
  const {
    mutateAsync: updateSubscriptionPaymentSource,
    isPending: isUpdatingSubscriptionPaymentSource,
  } = useMutation({
    mutationFn: ({
      placeId,
      paymentSourceId,
    }: {
      placeId: string;
      paymentSourceId: string;
    }) => updateSubscriptionPaymentSourceRequest(placeId, paymentSourceId),
    onSuccess: (_, { placeId, paymentSourceId }) => {
      queryClient.setQueryData<DetailedSubscription>(
        ["subscription", placeId],
        (subscription) => {
          if (subscription) {
            return produce(subscription, (draftSubscription) => {
              draftSubscription.payment_source_id = paymentSourceId;
            });
          }
        },
      );
    },
  });
  return {
    cancelSubscription,
    isCancellingSubscription,
    updateSubscriptionPlan,
    isUpdatingSubscriptionPlan,
    isUpdatingSubscriptionPlanSuccess,
    resetUpdateSubscriptionPlan,
    updateSubscriptionPaymentSource,
    isUpdatingSubscriptionPaymentSource,
  };
}
