import { JSX } from "react";

export default function DesktopLayout({
  customizeComponent,
  summaryComponent,
  paymentMethodComponent,
  successComponent,
}: {
  customizeComponent: JSX.Element;
  summaryComponent: JSX.Element;
  paymentMethodComponent: JSX.Element | undefined;
  successComponent: JSX.Element | undefined;
}) {
  if (successComponent) {
    return successComponent;
  }
  if (paymentMethodComponent) {
    return paymentMethodComponent;
  }
  return (
    <div className="flex flex-col gap-x-6 lg:flex-row">
      <div className="flex flex-col rounded-5xl bg-white p-4">
        {customizeComponent}
      </div>
      <div className="flex flex-1 flex-col pt-6">{summaryComponent}</div>
    </div>
  );
}
