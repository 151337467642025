import { ButtonProps, Button } from "react-aria-components";
import { clsx } from "clsx";
import { forwardRef } from "react";

interface UnstyledButtonProps extends ButtonProps {}
const UnstyledButton = forwardRef<HTMLButtonElement, UnstyledButtonProps>(
  ({ children, className, ...rest }, ref) => {
    const baseClassName = "transition-[background,border,margin,padding,color]";

    return (
      <Button
        {...rest}
        ref={ref}
        className={
          typeof className === "function"
            ? (renderProps) => clsx(className(renderProps), baseClassName)
            : clsx(className, baseClassName)
        }
      >
        {children}
      </Button>
    );
  },
);
export { UnstyledButton };
