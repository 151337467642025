import { formatDate } from "common/utils.ts";
import {
  fromAbsolute,
  getLocalTimeZone,
  parseAbsolute,
} from "@internationalized/date";
import useNextPaymentEstimate from "common/datahooks/useNextPaymentEstimate.tsx";
import { useParams } from "@tanstack/react-router";
import useSubscription from "common/datahooks/useSubscription.ts";
import { Skeleton } from "common/components/base";

export default function SubscriptionSummary() {
  const { placeId } = useParams({ from: "/$placeId" });
  const { nextPaymentEstimate } = useNextPaymentEstimate(placeId);
  const { subscription } = useSubscription(placeId);

  let mainContent = <Skeleton className="h-5 w-80 rounded-full" />;
  if (subscription) {
    const nextBillingDate = nextPaymentEstimate
      ? fromAbsolute(
          nextPaymentEstimate.billing_date * 1000,
          getLocalTimeZone(),
        )
      : undefined;

    if (subscription.status === "in_trial") {
      mainContent = (
        <>
          Your <span className="font-semibold text-purple-500">free trial</span>{" "}
          ends on{" "}
          {nextBillingDate && (
            <>
              <span className="font-semibold capitalize text-purple-500">
                {formatDate(nextBillingDate, "long", false)}
              </span>
            </>
          )}
          . After that you will be charged{" "}
          {nextPaymentEstimate ? (
            <span className="font-semibold text-purple-500">
              ${nextPaymentEstimate.total / 100}/{subscription.period}
            </span>
          ) : (
            <Skeleton className="h-4.5 w-10 rounded-full" />
          )}
        </>
      );
    } else if (subscription.status === "cancelled") {
      const cancellationDate = parseAbsolute(
        subscription.cancelled_at,
        getLocalTimeZone(),
      );
      mainContent = (
        <>
          Your subscription was canceled on{" "}
          <span className="font-semibold">
            {formatDate(cancellationDate, "none", false)}
          </span>
          . To continue using HeyRank, simply resubscribe
        </>
      );
    } else {
      mainContent = (
        <>
          Your next payment of{" "}
          {nextPaymentEstimate ? (
            <span className="font-semibold">
              ${nextPaymentEstimate.total / 100}
            </span>
          ) : (
            <Skeleton className="h-4.5 w-10 rounded-full" />
          )}{" "}
          will be charged on{" "}
          {nextBillingDate ? (
            <span className="font-semibold">
              {formatDate(nextBillingDate, "none", true)}
            </span>
          ) : (
            <Skeleton className="h-4.5 w-36 rounded-full" />
          )}
        </>
      );
    }
  }
  return <p className="text-sm">{mainContent}</p>;
}
