import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patch } from "@/src/common/helpers/HTTP.ts";
import {
  DetailedPlace,
  FreePlaceSettings,
  PaidPlaceSettings,
} from "common/types.ts";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";
import { produce } from "immer";
async function updatePlaceSettingsRequest(
  placeId: string,
  settings: FreePlaceSettings & Pick<PaidPlaceSettings, "schedule">,
): Promise<FreePlaceSettings & Pick<PaidPlaceSettings, "schedule">> {
  await patch(`v1/places/${placeId}/settings`, settings);
  return settings;
}

export default function usePlaceSettingsMutations(placeId: string) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updatePlaceSettings,
    isPending: isUpdatingPlaceSettings,
  } = useMutation({
    mutationFn: ({
      placeSettings,
    }: {
      placeSettings: FreePlaceSettings & Pick<PaidPlaceSettings, "schedule">;
    }) => updatePlaceSettingsRequest(placeId, placeSettings),
    onSuccess: (updatedSettings) => {
      queryClient.setQueryData<DetailedPlace>(
        getQueryKeys().places.details(placeId),
        (detailedPlace) => {
          if (detailedPlace) {
            return produce(detailedPlace, (draftDetailedPlace) => {
              draftDetailedPlace.settings = {
                ...draftDetailedPlace.settings,
                ...updatedSettings,
              };
            });
          }
        },
      );
    },
  });

  return {
    updatePlaceSettings,
    isUpdatingPlaceSettings,
  };
}
