import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Coordinates, type GridPoint as GridPointType } from "common/types.ts";
import { useSelectedPlace } from "@/src/contexts.ts";
import GridPoint from "./GridPoint.tsx";
import { useAppStore, useTwBreakpoint } from "common/hooks";
import { clsx } from "clsx";

function getDefaultZoomLevel(
  gridSize: number,
  gridDistance: number,
  isDesktop: boolean,
) {
  //the zoom level at grid size 5, with distance of 100m
  const baseZoom = isDesktop ? 15.2 : 14.4;
  const sizeIndex = [5, 7, 9, 11, 13, 15].indexOf(gridSize);
  return (
    baseZoom -
    (isDesktop ? 0.2 : 0.1) * sizeIndex -
    (gridDistance / 100 - 1) * 0.5
  );
}
export default function GridMap({
  gridPoints,
  selectedPoint,
  setSelectedPoint,
  gridSize,
  gridDistance,
  isDisabled,
}: {
  gridPoints: GridPointType[] | undefined;
  selectedPoint: Coordinates | undefined;
  setSelectedPoint: (point: Coordinates | undefined) => void;
  gridSize: number | undefined;
  gridDistance: number | undefined;
  isDisabled: boolean;
}) {
  const { selectedPlace } = useSelectedPlace();
  const isDesktop = useTwBreakpoint("lg");
  const [map, setMap] = useState<mapboxgl.Map>();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { longitude, latitude } = selectedPlace.location;

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      zoom: 12,
      cooperativeGestures: true,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
      center: { lon: longitude, lat: latitude },
      accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
    });

    setMap(map);

    const updatePadding = () => {
      if (mapContainerRef.current) {
        const hasPadding = window.innerWidth >= 1024;
        map.setPadding({
          top: hasPadding ? mapContainerRef.current.clientHeight / 10 : 0,
          bottom: 0,
          left: 0,
          right: hasPadding ? mapContainerRef.current.clientWidth / 2.5 : 0,
        });
      }
    };
    updatePadding();

    window.addEventListener("resize", updatePadding);
    return () => {
      map.remove();
      window.removeEventListener("resize", updatePadding);
    };
  }, []);

  useEffect(() => {
    if (selectedPlace && map) {
      const { longitude, latitude } = selectedPlace.location;

      map.setCenter({ lon: longitude, lat: latitude });
      /*mapRef.current.setMaxBounds([
        [longitude - 0.025, latitude - 0.025],
        [longitude + 0.025, latitude + 0.025],
      ]);*/
    }
  }, [selectedPlace, map]);

  useEffect(() => {
    if (map) {
      if (isDisabled) {
        map.dragPan.enable();
      } else {
        map.dragPan.disable();
      }
    }
  }, [isDisabled, map]);

  useEffect(() => {
    if (gridSize && gridDistance && map) {
      const defaultZoom = getDefaultZoomLevel(
        gridSize,
        gridDistance,
        isDesktop,
      );
      map.setMinZoom(undefined);
      map.setMaxZoom(undefined);
      map.setZoom(defaultZoom);
      map.setMaxZoom(defaultZoom + 0.6);
      map.setMinZoom(defaultZoom);

      const { longitude, latitude } = selectedPlace.location;
      map.setCenter({ lon: longitude, lat: latitude });
    }
  }, [gridSize, gridDistance, map]);

  return (
    <>
      <div
        className="absolute inset-0 size-full overflow-hidden rounded-xl lg:rounded-6xl"
        ref={mapContainerRef}
      >
        <div
          id="map"
          className={clsx(
            "size-full rounded-xl lg:rounded-6xl",
            isDisabled && "pointer-events-none blur-sm",
          )}
        ></div>
        {gridPoints &&
          map &&
          gridPoints.map((point) => {
            const { latitude, longitude } = point;
            const isSelected = selectedPoint
              ? latitude === selectedPoint.latitude &&
                longitude === selectedPoint.longitude
              : undefined;
            const markerCoords = {
              lng: longitude,
              lat: latitude,
            };
            return (
              <GridPoint
                key={JSON.stringify(markerCoords)}
                point={point}
                map={map}
                onClick={() => {
                  if (point.upgrade) {
                    useAppStore.getState().setIsPlanCustomizationOpen(true);
                  } else {
                    setSelectedPoint(
                      isSelected ? undefined : { longitude, latitude },
                    );
                  }
                }}
                isSelected={isSelected}
              />
            );
          })}
      </div>
      {isDisabled && (
        <div className="absolute inset-0 size-full rounded-xl bg-grey-200/80 lg:rounded-6xl" />
      )}
    </>
  );
}
