import {
  Calendar as AriaCalendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  Heading,
} from "react-aria-components";
import { Button, buttonStyles } from "../Button";

import AngleRight from "fontawesome/solid/angle-right.svg?react";
import AngleLeft from "fontawesome/solid/angle-left.svg?react";
import { useState } from "react";
import { CalendarDate, parseDate } from "@internationalized/date";
import { useTwBreakpoint } from "common/hooks";

export function Calendar({
  onApply,
  onCancel,
  initialSelectedDate,
}: {
  onApply: (date: string) => void;
  onCancel: () => void;
  initialSelectedDate: CalendarDate | undefined;
}) {
  const isDesktopOrTablet = useTwBreakpoint("md");
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    initialSelectedDate?.toString(),
  );

  return (
    <>
      <AriaCalendar
        value={selectedDate ? parseDate(selectedDate) : undefined}
        onChange={(date) => setSelectedDate(date.toString())}
        className="mx-auto"
      >
        <header className="mb-6 flex items-center justify-between md:mb-3">
          <Button
            variant="transparent"
            size={isDesktopOrTablet ? "sm" : "md"}
            slot="previous"
            isIconOnly
          >
            <AngleLeft />
          </Button>
          <Heading className="text-base font-medium" />
          <Button
            variant="transparent"
            size={isDesktopOrTablet ? "sm" : "md"}
            slot="next"
            isIconOnly
          >
            <AngleRight />
          </Button>
        </header>
        <CalendarGrid>
          <CalendarGridHeader>
            {(day) => (
              <CalendarHeaderCell className="text-xs font-medium text-grey-400">
                {day}
              </CalendarHeaderCell>
            )}
          </CalendarGridHeader>
          <tbody className="h-6 md:h-3" />
          <CalendarGridBody>
            {(date) => (
              <CalendarCell
                date={date}
                className={({ isSelected }) => {
                  return buttonStyles({
                    variant: isSelected ? "black" : "transparent",
                    size: "md",
                    isIconOnly: true,
                  });
                }}
              />
            )}
          </CalendarGridBody>
        </CalendarGrid>
      </AriaCalendar>
      <div className="mt-6 flex justify-between gap-x-2 md:mt-3">
        <Button
          size={isDesktopOrTablet ? "sm" : "lg"}
          variant="white"
          className="max-md:flex-1"
          onPress={onCancel}
        >
          Cancel
        </Button>
        <Button
          size={isDesktopOrTablet ? "sm" : "lg"}
          variant="black"
          className="max-md:flex-1"
          onPress={() => {
            if (selectedDate) {
              onApply(selectedDate);
            }
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
}
