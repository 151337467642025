import { Select } from "react-aria-components";
import {
  SelectButton,
  Popover,
  DropdownBox,
  DropdownBoxItem,
  DropdownActionButton,
  Badge,
} from "common/components/base";

import Cog from "fontawesome/regular/gear.svg?react";
import ChevronRight from "fontawesome/regular/chevron-right.svg?react";
import Bookmark from "fontawesome/solid/bookmark.svg?react";

import { Keyword as KeywordType } from "common/types.ts";
import { useNavigate } from "@tanstack/react-router";
import { ManageKeywords } from "@/src/pages/home/ManageKeywords";
import { useState } from "react";
import { useSelectedPlace } from "@/src/contexts.ts";
import { useAppStore } from "common/hooks";
export default function KeywordSelect({
  data,
  selectedKeywordId,
  isDisabled,
}: {
  data:
    | {
        keywords: KeywordType[];
        maxKeywords: number;
      }
    | undefined;
  selectedKeywordId: string | undefined;
  isDisabled: boolean;
}) {
  const { isDemoMode } = useSelectedPlace();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const navigate = useNavigate({ from: "/$placeId/home" });

  const selectedKeyword =
    data && selectedKeywordId
      ? data.keywords.find(({ id }) => id === selectedKeywordId)
      : undefined;

  return (
    <>
      <Select
        isOpen={isSelectOpen}
        onOpenChange={setIsSelectOpen}
        aria-label="Select a keyword"
        selectedKey={selectedKeyword ? selectedKeyword.id : null}
        onSelectionChange={(selection) => {
          navigate({
            search: {
              selectedKeywordId: selection as string,
            },
          });
        }}
      >
        <SelectButton
          leftIcon={selectedKeyword?.default ? <Bookmark /> : undefined}
          placeholder="Select a keyword"
          className="min-w-72 max-lg:w-full"
          size="lg"
          isDisabled={isDisabled}
        >
          {selectedKeyword && selectedKeyword.label}
        </SelectButton>
        <Popover className="w-[--trigger-width]">
          <DropdownActionButton
            size="lg"
            onPress={() => {
              setIsSelectOpen(false);
              if (isDemoMode) {
                useAppStore.getState().setIsAddModalOpen(true);
              } else {
                setIsModalOpen(true);
              }
            }}
          >
            <Cog />
            Manage keywords
            <ChevronRight />
          </DropdownActionButton>
          <DropdownBox size="lg" items={data ? data.keywords : []}>
            {({ id, label, difficulty, default: isDefault }) => (
              <DropdownBoxItem
                size="lg"
                leftComponent={isDefault ? <Bookmark /> : undefined}
                rightComponent={
                  <Badge size="sm" variant="grey">
                    {difficulty}
                  </Badge>
                }
                title={label}
                key={id}
                id={id}
              />
            )}
          </DropdownBox>
        </Popover>
      </Select>
      <ManageKeywords
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        keywords={data ? data.keywords : []}
        maxKeywords={data ? data.maxKeywords : 0}
      />
    </>
  );
}
