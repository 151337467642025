import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post } from "@/src/common/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/common/helpers/getQueryKeys.ts";
import { PaymentSource } from "common/types.ts";

async function addPaymentSourceRequest(
  source: "card" | "paypal" | "apple-pay",
  token: string,
): Promise<PaymentSource> {
  return (
    await post(`v1/billing/sources/${source}`, {
      token,
    })
  ).payment_source;
}

export default function usePaymentSourcesMutations() {
  const queryClient = useQueryClient();

  const {
    mutateAsync: addPaymentSource,
    isPending: isAddingPaymentSource,
    error: addPaymentSourceError,
  } = useMutation({
    mutationFn: ({
      source,
      token,
    }: {
      source: "card" | "paypal" | "apple-pay";
      token: string;
    }) => addPaymentSourceRequest(source, token),
    onSuccess: (paymentSource) => {
      queryClient.setQueryData<PaymentSource[]>(
        getQueryKeys().paymentSources.list(),
        (paymentSources) => {
          if (paymentSources) {
            return [...paymentSources, paymentSource];
          }
          return [paymentSource];
        },
      );
    },
  });

  return {
    addPaymentSource,
    isAddingPaymentSource,
    addPaymentSourceError,
  };
}
