import { useLabel } from "react-aria";
import { clsx } from "clsx";
import { Label } from "common/components/base";
import { cloneElement, JSX } from "react";
import BadgeCheck from "fontawesome/solid/badge-check.svg?react";

export default function SettingsItem({
  label,
  component,
  description,
  isColumn = false,
  isPremium = false,
}: {
  label: string;
  component: JSX.Element;
  description?: string;
  isColumn?: boolean;
  isPremium?: boolean;
}) {
  const { labelProps, fieldProps } = useLabel({ label });

  const labelTooltipProps: {
    tooltipContent?: string;
    tooltipIcon?: JSX.Element;
  } = {};
  if (isPremium) {
    labelTooltipProps.tooltipContent = "Pro plan feature";
    labelTooltipProps.tooltipIcon = <BadgeCheck className="fill-purple-400" />;
  }

  return (
    <div
      className={clsx(
        "flex justify-between",
        isColumn ? "flex-col gap-2" : "items-center",
      )}
    >
      <div className="flex flex-col">
        <Label size="lg" {...labelProps} {...labelTooltipProps}>
          {label}
        </Label>
        {description && (
          <span className="ml-1 text-xs font-medium text-grey-500">
            {description}
          </span>
        )}
      </div>
      <div className={isColumn ? "" : "w-44"}>
        {cloneElement(component, fieldProps)}
      </div>
    </div>
  );
}
