import { Badge, Button, RatingStars } from "common/components/base";
import { BasePlace } from "common/types.ts";
import LocationDot from "fontawesome/solid/location-dot.svg?react";

export default function SelectedPlaceCard({
  selectedPlace,
  resetSelectedPlace,
}: {
  selectedPlace: BasePlace;
  resetSelectedPlace: () => void;
}) {
  const { rating, rating_count, name, address } = selectedPlace;
  return (
    <div className="flex flex-col rounded-5xl border border-grey-300 p-6">
      <div className="mb-1 flex gap-x-0.5">
        <span className="text-base font-semibold">{rating}</span>
        <RatingStars rating={rating} />
        <span className="text-base text-grey-500">({rating_count})</span>
      </div>
      <span className="mb-2 text-2xl font-semibold">{name}</span>
      <div className="flex items-center justify-between gap-x-3">
        <Badge size="md" variant="grey">
          <LocationDot />
          <span className="truncate">{address}</span>
        </Badge>
        <Button variant="stroke" size="sm" onPress={resetSelectedPlace}>
          This isn’t me
        </Button>
      </div>
    </div>
  );
}
