import {
  NumberFieldProps as AriaNumberFieldProps,
  NumberField,
} from "react-aria-components";
import { InputButton, Label, Input, InputGroup } from "common/components/base";

import Plus from "fontawesome/regular/plus.svg?react";
import Minus from "fontawesome/regular/minus.svg?react";
import { tv } from "tailwind-variants";

const inputStyle = tv({
  base: "text-center",
  variants: {
    size: {
      lg: "px-12",
      md: "px-11",
      sm: "px-8",
    },
  },
});
export interface NumberInputProps extends Omit<AriaNumberFieldProps, "size"> {
  size: "sm" | "md" | "lg";
  label?: string;
}
export function NumberInput({ size, label, ...rest }: NumberInputProps) {
  return (
    <NumberField {...rest}>
      {label && <Label size={size}>{label}</Label>}
      <InputGroup>
        <InputButton size={size} slot="decrement">
          <Minus />
        </InputButton>
        <Input size={size} className={inputStyle({ size })} />
        <InputButton size={size} slot="increment">
          <Plus />
        </InputButton>
      </InputGroup>
    </NumberField>
  );
}
