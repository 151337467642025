import { Button, ButtonProps, UnstyledButton } from "common/components/base";
import { formatDate, getWeekDay } from "common/utils.ts";
import { clsx } from "clsx";
import Tooltip from "@/src/pages/home/DateSelector/Tooltip.tsx";
import MiniGrid from "@/src/pages/home/DateSelector/MiniGrid.tsx";
import { Snapshot } from "common/types.ts";
import { parseDate } from "@internationalized/date";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useTwBreakpoint } from "common/hooks";

import XMark from "fontawesome/regular/xmark.svg?react";

export default function DateSelectorButton({
  snapshot,
  prevSnapshot,
  selectedSnapshot,
}: {
  snapshot: Snapshot;
  prevSnapshot: Snapshot | undefined;
  selectedSnapshot: Snapshot | undefined;
}) {
  const navigate = useNavigate({ from: "/$placeId/home" });
  const isDesktop = useTwBreakpoint("lg");
  const { selectedDate, compareWithDate } = useSearch({
    from: "/$placeId/_layout/home",
  });

  const { date, grid_size, preview, average_rank } = snapshot;

  const isSelected = date === selectedDate;
  const isCompared = date === compareWithDate;

  const dateObject = parseDate(date);

  function setCompareWithDate(date: string | undefined) {
    navigate({
      search: (prevSearch) => ({ ...prevSearch, compareWithDate: date }),
    });
  }
  function setSelectedDate(
    selectedDate: string,
    compareWithDate: string | undefined,
  ) {
    navigate({
      search: (prevSearch) => ({
        ...prevSearch,
        selectedDate,
        compareWithDate,
      }),
    });
  }

  let buttonComponent;
  if (isDesktop) {
    let buttonVariant: ButtonProps["variant"] = "transparent";
    if (isSelected) {
      buttonVariant = "black";
    } else if (isCompared) {
      buttonVariant = "stroke";
    }
    buttonComponent = (
      <Button
        key={date}
        variant={buttonVariant}
        size="sm"
        className="capitalize"
        onPress={() => setSelectedDate(date, prevSnapshot?.date)}
      >
        {formatDate(dateObject, "short", false)}
      </Button>
    );
  } else {
    buttonComponent = (
      <UnstyledButton
        key={date}
        className={clsx(
          "flex w-11 flex-col items-center gap-y-1 rounded-3xl py-2.5 capitalize",
          isSelected && "bg-grey-900 text-white",
          isCompared && "border border-grey-300 text-grey-900",
        )}
        onPress={() => setSelectedDate(date, prevSnapshot?.date)}
      >
        <span className="text-xs">{getWeekDay(dateObject).slice(0, 3)}</span>
        <span className="text-sm font-medium">{dateObject.day}</span>
      </UnstyledButton>
    );
  }

  if (isSelected || !isDesktop) {
    return buttonComponent;
  }

  const rankChange = selectedSnapshot
    ? (average_rank - selectedSnapshot.average_rank) /
      Math.abs(selectedSnapshot.average_rank)
    : 0;

  return (
    <Tooltip buttonComponent={buttonComponent} key={date}>
      <Button
        variant={isCompared ? "stroke" : "green"}
        size="sm"
        onPress={() => setCompareWithDate(isCompared ? undefined : date)}
      >
        {isCompared && <XMark />}
        {isCompared ? "Remove date" : "Compare with"}
      </Button>
      <div className="flex items-center gap-x-2">
        <div className="h-3 w-2 rounded-md bg-yellow-300" />
        <span className="text-xs text-grey-400">Average Rank</span>
        <span className="ml-auto text-sm font-medium">{average_rank}</span>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="h-3 w-2 rounded-md bg-green-300" />
        <span className="text-xs text-grey-400">Versus selected</span>
        <span className="ml-auto text-sm font-medium">
          {selectedSnapshot && selectedSnapshot.average_rank === 0
            ? "N/A"
            : `${Math.round(rankChange * 100)}%`}
        </span>
      </div>
      <MiniGrid gridSize={grid_size} preview={preview} />
    </Tooltip>
  );
}
