import { FormEvent, ReactNode } from "react";
import { Button, MobileSheet, DialogHeading } from "common/components/base";
import Pen from "fontawesome/solid/pen.svg?react";
import { useTwBreakpoint } from "common/hooks";

export default function SettingsItem({
  isEditing,
  toggleIsEditing,
  label,
  onSubmit,
  editingComponent,
  children,
  isLoading,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
  label: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  editingComponent: ReactNode;
  children: ReactNode;
  isLoading: boolean;
}) {
  const isDesktopOrTablet = useTwBreakpoint("md");

  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSubmit(e);
  }

  return (
    <>
      <div className="flex items-center border-b border-b-grey-200 py-3 last:border-b-0 lg:px-3 lg:py-2">
        <span className="w-1/3 shrink-0 text-xs text-grey-600 lg:text-sm">
          {label}
        </span>
        <form
          onSubmit={onFormSubmit}
          className="flex grow items-center justify-between gap-y-2"
        >
          {isEditing && isDesktopOrTablet ? (
            <>
              {editingComponent}
              <div className="ml-auto flex gap-x-3">
                <Button
                  size="md"
                  variant="white"
                  onPress={toggleIsEditing}
                  isDisabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  variant="black"
                  type="submit"
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              {children}
              <Button
                variant="white"
                size="md"
                isIconOnly
                className="ml-auto"
                onPress={toggleIsEditing}
              >
                <Pen />
              </Button>
            </>
          )}
        </form>
      </div>
      {!isDesktopOrTablet && (
        <MobileSheet isOpen={isEditing} setIsOpen={toggleIsEditing}>
          <DialogHeading className="mb-2 text-center text-2xl font-semibold">
            Update {label}
          </DialogHeading>
          <form className="" onSubmit={onFormSubmit}>
            {editingComponent}
            <div className="mt-6 flex gap-x-2">
              <Button
                variant="white"
                size="lg"
                onPress={toggleIsEditing}
                className="flex-1"
                isDisabled={isLoading}
              >
                Go back
              </Button>
              <Button
                variant="black"
                size="lg"
                className="flex-1"
                type="submit"
                isLoading={isLoading}
              >
                Save
              </Button>
            </div>
          </form>
        </MobileSheet>
      )}
    </>
  );
}
