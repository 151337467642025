import { Button, DialogHeading } from "common/components/base";

import SuccessGraphic from "@/src/assets/trial-success.svg?react";

export default function Success({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex flex-col items-center">
      <SuccessGraphic className="mb-4 mt-32 size-48 lg:mt-8 lg:size-60" />
      <DialogHeading className="mb-2 text-center text-2xl font-semibold">
        Subscription has been successfully updated!
      </DialogHeading>
      <p className="mb-6 text-center text-grey-500">
        Enjoy upgraded features, boost local rankings and get greater visibility
        on Google Maps faster
      </p>
      <Button
        onPress={onClose}
        variant="black"
        size="md"
        className="w-full max-lg:mt-auto lg:ml-auto"
      >
        Okay!
      </Button>
    </div>
  );
}
