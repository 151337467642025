import { DependencyList, useLayoutEffect, useState } from "react";

export function usePagination<T>(
  itemsPerPage: number,
  items: T[] | undefined,
  dependencies?: DependencyList,
) {
  const [pageIndex, setPageIndex] = useState(0);

  useLayoutEffect(
    () => {
      setPageIndex(0);
    },
    dependencies || [items],
  );

  const pageItems = items
    ? items.slice(
        pageIndex * itemsPerPage,
        pageIndex * itemsPerPage + itemsPerPage,
      )
    : [];

  return {
    isPaginationVisible: items ? items.length > itemsPerPage : false,
    pageItems,
    paginationComponentProps: {
      pageIndex,
      setPageIndex,
      pagesCount: items ? Math.ceil(items.length / itemsPerPage) : 0,
    },
  };
}
