import { useQuery } from "@tanstack/react-query";
import { Invoice } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";

async function getInvoicesRequest(placeId: string): Promise<Invoice[]> {
  return (await get(`v1/places/${placeId}/invoices`)).invoices;
}
export default function usePlaceInvoices(placeId: string) {
  const { data: invoices, isLoading: isLoadingInvoices } = useQuery({
    queryKey: getQueryKeys().places.invoices(placeId),
    queryFn: () => getInvoicesRequest(placeId),
  });
  return { invoices, isLoadingInvoices };
}
