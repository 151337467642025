import { JSX } from "react";
import { Badge, BadgeProps } from "common/components/base";
import ArrowUp from "fontawesome/solid/arrow-up.svg?react";
import ArrowDown from "fontawesome/solid/arrow-down.svg?react";

export default function DeltaValue({
  selectedValue,
  compareWithValue,
}: {
  selectedValue: number;
  compareWithValue: number;
}) {
  const deltaValue =
    ((compareWithValue - selectedValue) / Math.abs(selectedValue)) * 100;
  let badgeVariant: BadgeProps["variant"];
  let icon: JSX.Element;

  if (deltaValue > 0) {
    badgeVariant = "green";
    icon = <ArrowUp />;
  } else {
    badgeVariant = "red";
    icon = <ArrowDown />;
  }
  return (
    <Badge size="md" variant={badgeVariant} className="max-lg:ml-2">
      {deltaValue === 0 ? deltaValue : deltaValue.toFixed(2)}%{icon}
    </Badge>
  );
}
