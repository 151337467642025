import PlacesSearchBox from "common/components/AddBusiness/PlacesSearchBox.tsx";
import { BasePlace, Coordinates } from "common/types.ts";
import { useEffect, useState } from "react";
import { useAppStore } from "common/hooks/useAppStore.ts";
import {
  Button,
  Checkbox,
  DialogHeading,
  fieldErrorStyles,
} from "common/components/base";

import AddAccountGraphic from "@/src/assets/add-account.svg?react";
import SelectedPlaceCard from "common/components/AddBusiness/SelectedPlaceCard.tsx";
import usePlaceMutations from "common/datahooks/usePlaceMutations.tsx";

export default function PlaceSelection({
  selectedPlace,
  setSelectedPlace,
  closeDialog,
  goToNextStep,
}: {
  selectedPlace: BasePlace | null;
  setSelectedPlace: (place: BasePlace | null) => void;
  closeDialog: () => void;
  goToNextStep: () => void;
}) {
  const {
    validatePlace,
    isValidatingPlace,
    validatePlaceError,
    resetValidatePlace,
  } = usePlaceMutations();
  const [isLocationEnabled, setIsLocationEnabled] = useState(
    useAppStore.getState().geoState === "granted",
  );
  const [location, setLocation] = useState<Coordinates | undefined>();
  const geoState = useAppStore((state) => state.geoState);

  function updateLocation() {
    setIsLocationEnabled(true);
    navigator.geolocation.getCurrentPosition(
      (location) => {
        const {
          coords: { longitude, latitude },
        } = location;
        useAppStore.getState().setGeoState("granted");
        setLocation({
          longitude,
          latitude,
        });
      },
      (positionError) => {
        if (positionError.PERMISSION_DENIED) {
          useAppStore.getState().setGeoState("denied");
          setIsLocationEnabled(false);
        }
      },
    );
  }
  function onConfirm() {
    if (selectedPlace) {
      validatePlace({ placeId: selectedPlace.id }).then(goToNextStep);
    }
  }
  function resetSelectedPlace() {
    setSelectedPlace(null);
    resetValidatePlace();
  }

  useEffect(() => {
    if (geoState === "granted") {
      updateLocation();
    }
  }, []);

  return (
    <>
      <AddAccountGraphic className="mx-auto mb-4 size-48 lg:my-12 lg:size-60" />
      <DialogHeading className="mb-2 text-center text-2xl font-semibold">
        Find your Google Business Profile
      </DialogHeading>
      <p className="mb-4 text-center text-sm text-grey-500 lg:text-base">
        Simply find your Google Business Profile to access personalized
        analytics, track your local rankings, and optimize your visibility on
        Google Maps and Local Pack.
      </p>
      {selectedPlace ? (
        <>
          <SelectedPlaceCard
            selectedPlace={selectedPlace}
            resetSelectedPlace={resetSelectedPlace}
          />
          {validatePlaceError && (
            <span className={fieldErrorStyles({ size: "lg" })}>
              This GBP is already registered. Please check if you selected the
              right one, or contact support in case there is an issue
            </span>
          )}
          <div className="mt-6 flex w-full flex-col-reverse gap-4 lg:flex-row">
            <Button
              variant="white"
              size="md"
              className="flex-1"
              onPress={closeDialog}
              isDisabled={isValidatingPlace}
            >
              Cancel
            </Button>
            <Button
              variant="black"
              size="md"
              className="flex-1"
              onPress={onConfirm}
              isLoading={isValidatingPlace}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <>
          <PlacesSearchBox
            selectPlace={(place) => setSelectedPlace(place)}
            location={isLocationEnabled ? location : undefined}
          />
          <Checkbox
            className="mt-2"
            isSelected={isLocationEnabled}
            onChange={updateLocation}
          >
            Enable location services in your browser for more accurate search
            results
          </Checkbox>
          <Button
            variant="white"
            size="md"
            className="mr-auto mt-6"
            onPress={closeDialog}
          >
            Go back
          </Button>
        </>
      )}
    </>
  );
}
