import { GridPoint } from "common/types.ts";
import { getRankName } from "common/utils.ts";
import { RANKS } from "@/src/constants.tsx";
import {
  Tooltip,
  TooltipTrigger,
  Popover,
  UnstyledButton,
  Skeleton,
} from "common/components/base";
import { clsx } from "clsx";

import CircleInfo from "fontawesome/solid/circle-info.svg?react";
import { DialogTrigger } from "react-aria-components";
import { useTwBreakpoint } from "common/hooks";

export default function GridStats({
  gridPoints,
}: {
  gridPoints: GridPoint[] | undefined;
}) {
  const isDesktop = useTwBreakpoint("lg");

  const rankingCounts = (gridPoints || []).reduce(
    (accumulator, { rank, upgrade }) => {
      if (!upgrade) {
        if (rank) {
          accumulator[getRankName(rank)] += 1;
        } else {
          accumulator.low += 1;
        }
      }
      return accumulator;
    },
    { high: 0, med: 0, low: 0 },
  );

  const Trigger = isDesktop ? TooltipTrigger : DialogTrigger;
  const Component = isDesktop ? Tooltip : Popover;

  return (
    <div className="pointer-events-auto flex items-center gap-x-5 rounded-full bg-white px-3 py-2 lg:mx-auto lg:gap-x-6 lg:rounded-5xl lg:px-6 lg:py-3">
      {Object.entries(rankingCounts).map(([key, count]) => {
        const { label, colorClassNames } = RANKS[key as keyof typeof RANKS];
        return (
          <div key={key} className="flex flex-1 items-center justify-center">
            <div
              className={clsx("mr-2 h-2 w-3 rounded-md", colorClassNames.bg)}
            />
            {gridPoints ? (
              <>
                <span className="mr-2 text-sm font-semibold lg:mr-1">
                  {count}
                </span>
                <span className="text-nowrap text-xs font-normal max-lg:hidden">
                  {label}
                </span>
              </>
            ) : (
              <Skeleton className="h-3 w-10 rounded-xl lg:w-20" />
            )}
          </div>
        );
      })}
      <Trigger>
        <UnstyledButton>
          <CircleInfo className="size-3 fill-grey-400" />
        </UnstyledButton>
        <Component
          variant="white"
          className="gap-y-2.5 px-4 pb-5 pt-3.5"
          placement="top"
        >
          {Object.values(RANKS).map(({ colorClassNames, detailedLabel }) => (
            <div
              className="flex items-center gap-x-2 text-xs"
              key={detailedLabel}
            >
              <div className={clsx("h-2 w-3 rounded-md", colorClassNames.bg)} />
              {detailedLabel}
            </div>
          ))}
        </Component>
      </Trigger>
    </div>
  );
}
