import { cloneElement, JSX } from "react";

export default function EmptyPlaceholder({
  title,
  subtitle,
  image,
}: {
  title: string;
  subtitle: string;
  image: JSX.Element;
}) {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      {cloneElement(image, { className: "lg:h-60 h-48" })}
      <h4 className="mb-2.5 mt-6 text-xl font-medium">{title}</h4>
      <p className="max-w-80 text-center text-base text-grey-500 lg:max-w-96">
        {subtitle}
      </p>
    </div>
  );
}
