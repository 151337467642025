import { string, object, email, pipe, InferOutput, maxLength } from "valibot";

export const EmailSchema = object({
  email: pipe(
    string("Email is required"),
    maxLength(70, "Email can't be more than 70 characters"),
    email("Invalid email address format"),
  ),
});
export type EmailSchemaType = InferOutput<typeof EmailSchema>;

export const PasswordSchema = object({
  password: pipe(string("Password is required")),
});
export type PasswordSchemaType = InferOutput<typeof PasswordSchema>;

export const NameSchema = object({
  firstName: pipe(
    string("First name is required"),
    maxLength(150, "First name can't be more than 150 characters"),
  ),
  lastName: pipe(
    string("Last name is required"),
    maxLength(150, "Last name can't be more than 150 characters"),
  ),
});
export type NameSchemaType = InferOutput<typeof NameSchema>;
