import { useQuery } from "@tanstack/react-query";
import { PaymentSource } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";

async function getPaymentMethodsRequest(): Promise<PaymentSource[]> {
  const { payment_sources } = await get("v1/billing/sources");
  return payment_sources;
}
export default function usePaymentSources() {
  const { data: paymentSources, isLoading: isLoadingPaymentSources } = useQuery(
    {
      queryKey: getQueryKeys().paymentSources.list(),
      queryFn: () => getPaymentMethodsRequest(),
    },
  );
  return { paymentSources, isLoadingPaymentSources };
}
