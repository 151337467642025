import { Badge } from "common/components/base";
import { Snapshot } from "common/types.ts";

import DeltaValue from "@/src/pages/home/SnapshotStats/DeltaValue.tsx";
import RankProgress from "@/src/pages/home/SnapshotStats/RankProgress.tsx";
import { clsx } from "clsx";

import MetricsDivider from "@/src/assets/metrics-divider.svg?react";

const stats: {
  label: string;
  valueKey: "average_rank" | "difficulty" | "search_volume";
}[] = [
  { label: "Keyword Difficulty", valueKey: "difficulty" },
  { label: "Search Volume", valueKey: "search_volume" },
  { label: "Avg. Rank", valueKey: "average_rank" },
];
export default function SnapshotStats({
  selectedSnapshot,
  compareWithSnapshot,
}: {
  selectedSnapshot: Snapshot | undefined;
  compareWithSnapshot: Snapshot | undefined;
}) {
  return (
    <div className="pointer-events-auto flex flex-col rounded-5xl bg-white max-lg:divide-y max-lg:divide-grey-300 lg:flex-row lg:px-4.5 lg:py-3.5">
      {stats.map(({ label, valueKey }, index) => {
        const selectedValue = selectedSnapshot && selectedSnapshot[valueKey];
        const compareWithValue =
          compareWithSnapshot && compareWithSnapshot[valueKey];
        const isGraph =
          valueKey === "difficulty" &&
          !!selectedValue &&
          compareWithValue !== null;

        return (
          <>
            <div
              key={index}
              className="flex flex-1 flex-row items-center py-3 lg:flex-col lg:gap-y-2 lg:px-4 lg:py-0"
            >
              <span className="text-sm lg:text-xs lg:font-medium lg:text-grey-500">
                {label}
              </span>
              <div className="relative flex flex-col items-center max-lg:ml-auto lg:h-16">
                {isGraph && (
                  <RankProgress
                    selectedDifficulty={selectedValue}
                    compareWithDifficulty={compareWithValue}
                  />
                )}
                {selectedSnapshot ? (
                  <div
                    className={clsx(
                      "flex items-center gap-x-1 max-lg:ml-auto lg:mb-2 lg:gap-x-1.5",
                      isGraph ? "lg:mt-9" : "lg:mt-6",
                    )}
                  >
                    <span className="text-lg font-medium lg:text-2xl">
                      {selectedValue === null ? (
                        <Badge size="md" variant="grey">
                          Unavailable
                        </Badge>
                      ) : (
                        selectedValue
                      )}
                    </span>
                    {compareWithSnapshot && (
                      <div className="flex items-center gap-x-1.5 text-sm text-grey-500 lg:text-lg">
                        |{" "}
                        {compareWithValue === null ? (
                          <Badge size="md" variant="grey">
                            Unavailable
                          </Badge>
                        ) : (
                          compareWithValue
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <Badge
                    className="lg:mb-2 lg:mt-6"
                    size="md"
                    variant="skeleton"
                  />
                )}
              </div>
              {!!selectedValue &&
                !!compareWithValue &&
                selectedValue !== compareWithValue && (
                  <DeltaValue
                    selectedValue={selectedValue}
                    compareWithValue={compareWithValue}
                  />
                )}
            </div>
            {index < stats.length - 1 && (
              <MetricsDivider className="my-auto max-lg:hidden" />
            )}
          </>
        );
      })}
    </div>
  );
}
