import { routes } from "@/src/constants.tsx";
import { RouterLink, UserAvatar } from "common/components/base";
import useCustomer from "common/datahooks/useCustomer.ts";

export default function MobileNavBar() {
  const { customer } = useCustomer();
  return (
    <div className="fixed inset-x-0 bottom-4 z-popover flex justify-center">
      <div className="flex gap-x-2 rounded-full bg-grey-900/10 p-1 backdrop-blur-xl lg:hidden">
        {routes.map(({ label, icon }) => {
          return (
            <RouterLink
              key={label}
              to={`/$placeId/${label}`}
              className="flex h-14 items-center justify-center rounded-full transition-[width,background-color] [&_svg]:size-4"
              activeProps={{ className: "w-24 bg-grey-900 [&_svg]:fill-white" }}
              inactiveProps={{
                className: "w-14 bg-white [&_svg]:fill-bg-grey-900",
              }}
            >
              {icon}
            </RouterLink>
          );
        })}
        {customer && (
          <RouterLink to="/account">
            <UserAvatar email={customer.email} size="lg" />
          </RouterLink>
        )}
      </div>
    </div>
  );
}
