import { useState } from "react";
import { BasePlace, Coordinates, Place } from "common/types.ts";
import usePlacesSearch from "@/src/common/datahooks/usePlacesSearch.tsx";
import { ComboBox } from "react-aria-components";
import { useDebounce } from "common/utils.ts";
import {
  Input,
  DropdownBox,
  DropdownBoxItem,
  Popover,
} from "common/components/base";
export default function PlacesSearchBox({
  selectPlace,
  location,
}: {
  selectPlace: (place: BasePlace) => void;
  location: Coordinates | undefined;
}) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { places, isLoadingPlaces } = usePlacesSearch(
    debouncedSearchText,
    location,
  );

  let popoverContent;
  if (
    places &&
    places.length &&
    !isLoadingPlaces &&
    !!debouncedSearchText &&
    !!searchText
  ) {
    popoverContent = (
      <DropdownBox<Place> size="sm">
        {(place) => (
          <DropdownBoxItem
            key={place.id}
            id={place.id}
            size="sm"
            title={place.name}
            subtitle={place.address}
          />
        )}
      </DropdownBox>
    );
  } else {
    let placeholderText = "Loading...";
    if (!searchText) {
      placeholderText = "Start typing to search";
    } else if (places && !places.length) {
      placeholderText = "No results found";
    }
    popoverContent = (
      <div className="flex h-40 items-center justify-center">
        {placeholderText}
      </div>
    );
  }

  return (
    <ComboBox
      aria-label="Search GBP profile"
      autoFocus
      items={places}
      allowsEmptyCollection
      allowsCustomValue
      inputValue={searchText}
      onInputChange={(text) => setSearchText(text)}
      onSelectionChange={(selectedId) => {
        if (places) {
          const targetPlace = places.find(({ id }) => id === selectedId);
          if (targetPlace) {
            selectPlace(targetPlace);
          }
        }
      }}
    >
      <Input
        size="lg"
        className="w-full"
        placeholder="Type in your GBP name..."
      />
      <Popover className="w-[--trigger-width]" shouldFlip={false}>
        {popoverContent}
      </Popover>
    </ComboBox>
  );
}
