import { createFileRoute } from "@tanstack/react-router";

import Invoices from "@/src/pages/settings/Invoices.tsx";
import Subscription from "@/src/pages/settings/Subscription.tsx";
import { useSelectedPlace } from "@/src/contexts.ts";

export const Route = createFileRoute("/$placeId/_layout/settings")({
  component: Settings,
  staticData: {
    pageTitle: "Account Settings",
  },
});
function Settings() {
  const { isDemoMode } = useSelectedPlace();

  return (
    <>
      <Subscription />
      {!isDemoMode && <Invoices />}
    </>
  );
}
