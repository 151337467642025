import usePlaces from "@/src/common/datahooks/usePlaces";
import { SelectedPlaceProvider } from "@/src/contexts";
import { getDummyPlace } from "@/src/dummyGenerator";
import DesktopNavBar from "@/src/pages/layout/DesktopNavBar.tsx";
import { createFileRoute, Outlet, useMatches } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { clsx } from "clsx";
import PlaceCard from "@/src/pages/layout/PlaceCard.tsx";
import PlaceSelector from "@/src/pages/layout/PlaceSelector.tsx";
import MobileNavBar from "@/src/pages/layout/MobileNavBar.tsx";
import { useAppStore, useTwBreakpoint } from "common/hooks";
import { useIntercom } from "react-use-intercom";
import { PlanCustomization } from "common/components/PlanCustomization";
import Banner from "@/src/pages/layout/Banner.tsx";
import DebugPopup from "@/src/pages/layout/DebugPopup.tsx";

export const Route = createFileRoute("/$placeId/_layout")({
  component: Layout,
  beforeLoad: ({ params }) => {
    localStorage.setItem("selectedPlaceId", params.placeId);
  },
});
function Layout() {
  const isDesktop = useTwBreakpoint("lg");
  const isXlDesktop = useTwBreakpoint("xl");
  const is2XlDesktop = useTwBreakpoint("2xl");

  const { update } = useIntercom();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { placeId } = Route.useParams();
  const matches = useMatches();
  const { places } = usePlaces();
  const { isPlanCustomizationOpen, setIsPlanCustomizationOpen } = useAppStore();

  useEffect(() => {
    if (isDesktop) {
      update({ hideDefaultLauncher: false });
      return () => update({ hideDefaultLauncher: true });
    }
  }, [isDesktop]);

  const isDemo = !!getDummyPlace(placeId);

  let selectedPlace;
  if (isDemo) {
    selectedPlace = getDummyPlace(placeId);
  } else if (places) {
    const foundPlace = places.find(({ id }) => id === placeId);
    if (foundPlace) {
      selectedPlace = foundPlace;
    }
  }
  const isBannerUnderHeader = !isXlDesktop || (isNavExpanded && !is2XlDesktop);
  return (
    <SelectedPlaceProvider value={selectedPlace}>
      <div className="fixed -z-base h-dvh w-full bg-grey-100 bg-[radial-gradient(57.48%_70.31%_at_50%_87.27%,#C5FCC5_0%,rgba(197,252,197,0)_100%)]" />
      <div className="flex w-full overflow-hidden">
        {isDesktop ? (
          <DesktopNavBar
            isNavExpanded={isNavExpanded}
            setIsNavExpanded={setIsNavExpanded}
          />
        ) : (
          <MobileNavBar />
        )}
        <main
          className={clsx(
            "flex w-full flex-col gap-y-2 overflow-hidden px-2 pb-24 pt-20 transition-[margin] lg:gap-y-4 lg:py-5 lg:pl-0 lg:pr-5",
            isNavExpanded ? "lg:ml-72" : "lg:ml-24",
          )}
        >
          <div className="bg-red fixed top-10"></div>
          <div className="inset-x-0 top-0 z-base flex items-center justify-between max-lg:fixed max-lg:bg-grey-100 max-lg:px-2 max-lg:py-4 lg:gap-x-8">
            <h1 className="text-nowrap text-2xl font-medium">
              {matches[matches.length - 1].staticData.pageTitle}
            </h1>
            {!isBannerUnderHeader && <Banner />}
            {import.meta.env.MODE !== "production" && <DebugPopup />}
            <PlaceSelector />
          </div>
          {isBannerUnderHeader && <Banner />}
          <PlaceCard />
          <Outlet />
        </main>
      </div>
      <PlanCustomization
        isOpen={isPlanCustomizationOpen}
        setIsOpen={setIsPlanCustomizationOpen}
      />
    </SelectedPlaceProvider>
  );
}
