import { Button, DialogHeading } from "common/components/base";
import SuccessGraphic from "@/src/assets/trial-success.svg?react";

export default function Success({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex flex-col items-center">
      <SuccessGraphic className="mb-4 mt-8 size-48 lg:size-60" />
      <DialogHeading className="mb-2 text-center text-2xl font-semibold">
        Success! Your 7-day free trial period has started!
      </DialogHeading>
      <p className="mb-6 text-center text-grey-500">
        Enjoy 7 days of full access to HeyRank's features, free of charge!
      </p>
      <Button onPress={onClose} variant="black" size="md" className="ml-auto">
        Go to Dashboard
      </Button>
    </div>
  );
}
