import { useQuery } from "@tanstack/react-query";
import { PaymentEstimate } from "common/types.ts";
import { post } from "@/src/common/helpers/HTTP.ts";
import useSubscription from "common/datahooks/useSubscription.ts";

async function getNextPaymentEstimateRequest(
  placeId: string,
): Promise<PaymentEstimate> {
  const { estimate } = await post(`v1/places/${placeId}/renew-estimate`);
  return {
    ...estimate,
    keyword_count: estimate.keywords,
    drives: estimate.daily_drives,
    schedule: estimate.frequency,
  };
}
//this returns the next payment estimate for the user's current plan and billing frequency
export default function useNextPaymentEstimate(placeId: string) {
  const { subscription } = useSubscription(placeId);
  const { data: nextPaymentEstimate, isLoading: isLoadingNextPaymentEstimate } =
    useQuery({
      queryKey: ["nextPaymentEstimate", placeId],
      queryFn: () => getNextPaymentEstimateRequest(placeId),
      enabled: subscription && subscription.status !== "cancelled",
    });
  return { nextPaymentEstimate, isLoadingNextPaymentEstimate };
}
