import { JSX } from "react";
import useKeywordsMutations from "common/datahooks/useKeywordsMutations.tsx";

export default function DeleteKeywordButton({
  keywordId,
  renderDeleteButton,
}: {
  keywordId: string;
  renderDeleteButton: (onPress: () => void) => JSX.Element;
}) {
  const { removeKeyword } = useKeywordsMutations();

  return renderDeleteButton(() => removeKeyword({ keywordId }));
}
