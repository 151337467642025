import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { SubscriptionStatus } from "common/types.ts";

interface DebugOptions {
  subscriptionStatus: SubscriptionStatus | undefined;
}

export type AppStore = {
  isAddModalOpen: boolean;
  setIsAddModalOpen: (isAddModalOpen: boolean) => void;

  isPlanCustomizationOpen: boolean;
  setIsPlanCustomizationOpen: (isPlanCustomizationOpen: boolean) => void;

  geoState: PermissionState;
  setGeoState: (geoState: PermissionState) => void;

  debugOptions: DebugOptions;
  setDebugOptions: (debugOptions: DebugOptions) => void;
};

export const useAppStore = create(
  devtools<AppStore>(
    (set) => ({
      isAddModalOpen: false,
      setIsAddModalOpen: (isAddModalOpen) => set({ isAddModalOpen }),

      isPlanCustomizationOpen: false,
      setIsPlanCustomizationOpen: (isPlanCustomizationOpen) =>
        set({ isPlanCustomizationOpen }),

      geoState: "prompt",
      setGeoState: (geoState) => set({ geoState }),

      debugOptions: { subscriptionStatus: undefined },
      setDebugOptions: (debugOptions) => set({ debugOptions }),
    }),
    { name: "App Store" },
  ),
);
