import { createRootRoute, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { jwtDecode } from "jwt-decode";
import { getAuthUrl } from "@/src/common/helpers/auth.ts";
import { useEffect } from "react";
import { useAppStore } from "common/hooks";
import { IntercomProvider } from "react-use-intercom";
import { placesQueryOptions } from "@/src/common/datahooks/usePlaces.ts";
import { redirect } from "@tanstack/react-router";
import { AddBusiness } from "common/components/AddBusiness";
import { dummyPlaces } from "@/src/dummyGenerator.ts";
import { ErrorBoundary } from "@sentry/react";
import { Button } from "common/components/base";
import { useUserback } from "@userback/react";
import useCustomer from "common/datahooks/useCustomer.ts";
import { queryClient } from "@/src/main.tsx";

const { hash } = window.location;
if (hash) {
  const token = hash.substring(1);

  if (token !== "") {
    localStorage.setItem("auth", token);
  }
}
export const Route = createRootRoute({
  beforeLoad: async () => {
    const token = localStorage.getItem("auth");
    let isAuthenticated = false;

    if (token) {
      const { exp } = jwtDecode(token);
      const expirationTime = (exp as number) * 1000 - 60000;

      if (Date.now() < expirationTime) {
        isAuthenticated = true;
      }
    }
    if (!isAuthenticated) {
      window.location.href = await getAuthUrl();
    }
  },
  loader: async ({ params, location }) => {
    const places = await queryClient.ensureQueryData(placesQueryOptions);
    let redirectToPlaceId;

    if ("placeId" in params) {
      /* empty */
    } else if (location.pathname === "/") {
      if (places && places.length) {
        redirectToPlaceId = places[0].id;
      } else {
        redirectToPlaceId = dummyPlaces[0].id;
      }
    }

    document.getElementById("loader-container")?.remove();

    if (redirectToPlaceId) {
      throw redirect({
        to: "/$placeId/home",
        replace: true,
        params: { placeId: redirectToPlaceId },
        search: (search) => search,
      });
    }
  },
  component: Root,
});
function Root() {
  const { isAddModalOpen, setIsAddModalOpen } = useAppStore();
  const { customer } = useCustomer();
  const {
    identify,
    isLoaded: isUserbackLoaded,
    setData,
    setEmail,
    setName,
  } = useUserback();

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      useAppStore.getState().setGeoState(result.state);
    });
  }, []);

  useEffect(() => {
    if (customer && import.meta.env.MODE === "production") {
      const { id, email, first_name, last_name } = customer;
      const name = `${first_name} ${last_name}`;
      if (isUserbackLoaded()) {
        identify(id, {
          email,
          name,
        });
      } else {
        setData({ id, email, name });
      }
      setEmail(email);
      setName(name);
    }
  }, [customer]);

  return (
    <ErrorBoundary
      fallback={({ resetError }) => (
        <div className="flex h-screen w-full flex-col items-center justify-center gap-4">
          <h1 className="text-2xl font-semibold">Something went wrong!</h1>
          <Button onPress={resetError} variant="black" size="md">
            Refresh
          </Button>
        </div>
      )}
    >
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID} autoBoot>
        <Outlet />
        <AddBusiness isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen} />
        <ReactQueryDevtools />
      </IntercomProvider>
      {process.env.NODE_ENV !== "production" && <TanStackRouterDevtools />}
    </ErrorBoundary>
  );
}
