import { Keyword } from "common/types.ts";
import KeywordsTable from "@/src/pages/home/ManageKeywords/KeywordsTable.tsx";
import { useTwBreakpoint } from "common/hooks";
import KeywordsList from "@/src/pages/home/ManageKeywords/KeywordsList.tsx";

export default function ViewKeywords({
  keywords,
  closeModal,
}: {
  keywords: Keyword[];
  closeModal: () => void;
}) {
  const isDesktopOrTablet = useTwBreakpoint("md");

  const Component = isDesktopOrTablet ? KeywordsTable : KeywordsList;

  return <Component keywords={keywords} closeModal={closeModal} />;
}
