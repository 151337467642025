import { useQuery } from "@tanstack/react-query";
import { get } from "common/helpers/HTTP.ts";
import { Keyword } from "common/types.ts";
import { useParams } from "@tanstack/react-router";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";

async function getKeywordSuggestions(placeId: string): Promise<Keyword[]> {
  const { suggestions } = await get(
    `v1/places/${placeId}/keywords/suggestions`,
  );
  return suggestions;
}
export default function useKeywordSuggestions() {
  const { placeId } = useParams({ from: "/$placeId" });

  const { data: keywordSuggestions, isLoading: isLoadingKeywordSuggestions } =
    useQuery({
      queryKey: getQueryKeys().keywords.suggestions(placeId),
      queryFn: () => getKeywordSuggestions(placeId),
    });

  return {
    keywordSuggestions,
    isLoadingKeywordSuggestions,
  };
}
