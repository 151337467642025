import KeywordsTable from "@/src/pages/home/ManageKeywords/KeywordsTable.tsx";
import { Keyword } from "common/types.ts";
import useKeywordSuggestions from "common/datahooks/useKeywordsSuggestions.ts";
import { useLayoutEffect, useState } from "react";
import { useTwBreakpoint } from "common/hooks";
import KeywordsList from "@/src/pages/home/ManageKeywords/KeywordsList.tsx";

export default function AddKeywords({
  searchText,
  keywords,
  maxKeywords,
  closeModal,
}: {
  searchText: string;
  keywords: Keyword[];
  maxKeywords: number;
  closeModal: () => void;
}) {
  const isDesktopOrTablet = useTwBreakpoint("md");
  const { keywordSuggestions } = useKeywordSuggestions();
  const [suggestionsState, setSuggestionsState] = useState<Keyword[]>();

  useLayoutEffect(() => {
    if (!suggestionsState && keywordSuggestions) {
      setSuggestionsState(keywordSuggestions);
    }
  }, [keywordSuggestions, suggestionsState]);

  if (suggestionsState) {
    const filteredSuggestions = suggestionsState.filter(({ label }) => {
      return searchText
        ? label.toLowerCase().includes(searchText.toLowerCase())
        : true;
    });
    const Component = isDesktopOrTablet ? KeywordsTable : KeywordsList;
    return (
      <Component
        keywords={keywords}
        maxKeywords={maxKeywords}
        keywordSuggestions={filteredSuggestions}
        searchText={searchText}
        closeModal={closeModal}
      />
    );
  }
  return (
    <div className="my-40 flex flex-col">
      <h3 className="mb-2.5 text-center text-xl font-medium">
        Search for new keywords here
      </h3>
      <p className="text-center text-base text-grey-500">
        Lorem ipsum dolor sit amet consectetur. Etiam suscipit sit diam in.
      </p>
    </div>
  );
}
