import { JSX, useEffect, useState } from "react";
import { DialogTrigger } from "react-aria-components";
import { Button, Modal } from "../base";
import DrivesSettings from "@/src/common/components/GridAndDrivesSettings/DrivesSettings.tsx";
import GridSettings from "@/src/common/components/GridAndDrivesSettings/GridSettings.tsx";
import { useAppStore, useTwBreakpoint } from "common/hooks";
import { useSelectedPlace } from "@/src/contexts.ts";
import SettingsMap from "common/components/GridAndDrivesSettings/SettingsMap.tsx";
import { PaidAndFreePlaceSettings } from "common/types.ts";
import { useParams } from "@tanstack/react-router";
import useAllSettings from "common/datahooks/useAllSettings.ts";
import usePlaceSettingsMutations from "common/datahooks/usePlaceSettingsMutations.tsx";
import usePlace from "common/datahooks/usePlace.tsx";
import { PlanCustomization } from "common/components/PlanCustomization";

export function GridAndDrivesSettings({
  renderTriggerComponent,
  initialTab,
}: {
  renderTriggerComponent: (onPress?: () => void) => JSX.Element;
  initialTab: "grid" | "drives";
}) {
  const { placeId } = useParams({ from: "/$placeId" });
  const isDesktop = useTwBreakpoint("lg");
  const { isDemoMode } = useSelectedPlace();
  const [selectedTab, setSelectedTab] = useState<"grid" | "drives">(initialTab);
  const { place, isLoadingPlace } = usePlace(placeId);
  const { updatePlaceSettings, isUpdatingPlaceSettings } =
    usePlaceSettingsMutations(placeId);
  const { allSettings } = useAllSettings();
  const [selectedSettings, setSelectedSettings] =
    useState<PaidAndFreePlaceSettings>();
  const [isCustomizationOpen, setIsCustomizationOpen] = useState(false);

  const placeSettings = place?.settings;

  function resetSettings() {
    if (placeSettings) {
      setSelectedSettings(placeSettings);
    }
  }

  useEffect(() => {
    resetSettings();
  }, [placeSettings]);

  if (isDemoMode) {
    return renderTriggerComponent(() =>
      useAppStore.getState().setIsAddModalOpen(true),
    );
  }

  function onSave(closeDialog: () => void) {
    if (selectedSettings) {
      const { grid_distance, drives_working_hours, drives_radius, schedule } =
        selectedSettings;
      updatePlaceSettings({
        placeSettings: {
          grid_distance,
          drives_working_hours,
          drives_radius,
          schedule,
        },
      }).then(() => {
        closeDialog();
      });
    }
  }

  const tabsComponents = (
    <div className="flex gap-x-2 max-lg:justify-center max-lg:py-1.5">
      <Button
        size="sm"
        variant={selectedTab === "grid" ? "black" : "transparent"}
        onPress={() => setSelectedTab("grid")}
      >
        Grid settings
      </Button>
      <Button
        size="sm"
        variant={selectedTab === "drives" ? "black" : "transparent"}
        onPress={() => setSelectedTab("drives")}
      >
        Drives settings
      </Button>
    </div>
  );

  let isUpsell = false;
  let isDowngrade = false;
  let hasChanges = false;

  if (selectedSettings && placeSettings && allSettings) {
    const gridValue = allSettings.grid_size.options.find(
      ({ id }) => id === placeSettings.grid_size,
    )?.value;
    const selectedGridValue = allSettings.grid_size.options.find(
      ({ id }) => id === selectedSettings.grid_size,
    )?.value;

    isUpsell =
      selectedSettings.drives > placeSettings.drives ||
      selectedSettings.schedule.length > placeSettings.schedule.length ||
      (!!gridValue && !!selectedGridValue && selectedGridValue > gridValue);

    isDowngrade =
      selectedSettings.drives < placeSettings.drives ||
      selectedSettings.schedule.length < placeSettings.schedule.length ||
      (!!gridValue && !!selectedGridValue && selectedGridValue < gridValue);

    hasChanges =
      place.settings.drives_radius !== selectedSettings.drives_radius ||
      place.settings.grid_distance !== selectedSettings.grid_distance ||
      place.settings.drives_working_hours !==
        selectedSettings.drives_working_hours ||
      place.settings.schedule.some(
        (day) => !selectedSettings.schedule.includes(day),
      );
  }

  const SettingsComponent =
    selectedTab === "drives" ? DrivesSettings : GridSettings;

  return (
    <>
      <DialogTrigger>
        {renderTriggerComponent()}
        <Modal
          className="flex-col gap-y-4 lg:flex-row lg:gap-x-6"
          variant="white"
          onAfterClose={resetSettings}
        >
          {({ close }) => (
            <>
              {!isDesktop && tabsComponents}
              <SettingsMap
                selectedTab={selectedTab}
                gridSize={
                  allSettings?.grid_size.options.find(
                    ({ id }) => id === selectedSettings?.grid_size,
                  )?.value
                }
                gridDistance={selectedSettings?.grid_distance}
                drivesRadius={selectedSettings?.drives_radius}
              />
              <div className="flex flex-col gap-y-6 max-lg:grow max-lg:px-3 lg:w-96">
                {isDesktop && tabsComponents}
                <SettingsComponent
                  settings={selectedSettings}
                  setSettingsValue={(key, value) => {
                    if (selectedSettings) {
                      setSelectedSettings({
                        ...selectedSettings,
                        [key]: value,
                      });
                    }
                  }}
                />
                {isUpsell || isDowngrade ? (
                  <Button
                    variant="purple"
                    size="md"
                    className="mt-auto lg:ml-auto"
                    onPress={() => {
                      close();
                      setIsCustomizationOpen(true);
                    }}
                  >
                    {isUpsell ? "Upgrade subscription" : "Modify subscription"}
                  </Button>
                ) : (
                  <Button
                    variant="black"
                    size="md"
                    className="mt-auto lg:ml-auto"
                    onPress={() => onSave(close)}
                    isDisabled={isLoadingPlace || !hasChanges}
                    isLoading={isUpdatingPlaceSettings}
                  >
                    Save changes
                  </Button>
                )}
              </div>
            </>
          )}
        </Modal>
      </DialogTrigger>
      <PlanCustomization
        isOpen={isCustomizationOpen}
        setIsOpen={setIsCustomizationOpen}
        initialSettings={
          selectedSettings && {
            drives: selectedSettings.drives,
            schedule: selectedSettings.schedule,
            grid_size: selectedSettings.grid_size,
          }
        }
      />
    </>
  );
}
