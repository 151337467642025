import {
  type SliderProps as AriaSliderProps,
  Slider as AriaSlider,
  SliderThumb,
  SliderTrack,
} from "react-aria-components";
import { JSX } from "react";
import { Label } from "common/components/base";
import { clsx } from "clsx";

interface SliderProps<T> extends Omit<AriaSliderProps<T>, "children"> {
  size: "sm" | "md" | "lg";
  label?: string;
}

export function Slider<T extends number | number[]>({
  size,
  label,
  className,
  ...props
}: SliderProps<T>) {
  return (
    <AriaSlider {...props} className={clsx(className, "flex-1")}>
      {label && <Label size={size}>{label}</Label>}
      <SliderTrack className="flex h-3 items-center">
        {({ state }) => {
          const stepMarkComponents: JSX.Element[] = [];
          const { step, maxValue, minValue } = props;
          if (step && maxValue && minValue) {
            for (
              let i =
                state.getThumbPercent(0) * (maxValue - minValue) + minValue;
              i <= maxValue;
              i += step
            ) {
              stepMarkComponents.push(
                <div
                  className="absolute size-2.5 rounded-full bg-grey-200"
                  style={{
                    left: `${((i - minValue) * 100) / (maxValue - minValue)}%`,
                  }}
                />,
              );
            }
          }
          return (
            <>
              <div className="h-0.5 w-full rounded-full bg-grey-200" />
              <div className="absolute inset-x-0 -ml-1.5 mr-1.5 flex items-center">
                {stepMarkComponents}
              </div>
              <div
                className="absolute h-1 rounded-full bg-grey-900"
                style={{ width: state.getThumbPercent(0) * 100 + "%" }}
              />
              {state.values.map((_, i) => (
                <SliderThumb
                  className="top-1/2 size-3 rounded-full bg-grey-900"
                  key={i}
                  index={i}
                />
              ))}
            </>
          );
        }}
      </SliderTrack>
    </AriaSlider>
  );
}
