import { useQuery } from "@tanstack/react-query";
import { AllSettings } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";

async function getAllSettingsRequest(): Promise<AllSettings> {
  const { possible_values } = await get(`v1/billing/values`);
  // this is supposed to be temporary
  return {
    ...possible_values,
    schedule: possible_values.frequency,
    drives: possible_values.daily_drives,
    keyword_count: possible_values.keywords,
  };
}
export default function useAllSettings() {
  const { data: allSettings, isLoading: isLoadingAllSettings } = useQuery({
    queryKey: ["allSettings"],
    refetchOnMount: false,
    queryFn: () => getAllSettingsRequest(),
  });

  return { allSettings, isLoadingAllSettings };
}
