import useKeywordsMutations from "@/src/common/datahooks/useKeywordsMutations.tsx";
import { Button } from "common/components/base";

import Check from "fontawesome/solid/check.svg?react";
import XMark from "fontawesome/regular/xmark.svg?react";
import { useAppStore } from "common/hooks";

export default function AddKeyword({
  newKeywordData,
  addedKeywordId,
  isLimitReached,
  closeModal,
}: {
  newKeywordData: { label: string } | { id: string };
  addedKeywordId: string | undefined;
  isLimitReached: boolean;
  closeModal: () => void;
}) {
  const { addKeyword, removeKeyword, isAddingKeyword, isRemovingKeyword } =
    useKeywordsMutations();

  if (addedKeywordId) {
    return (
      <Button
        size="sm"
        variant="stroke"
        isDisabled={isRemovingKeyword}
        onPress={() => removeKeyword({ keywordId: addedKeywordId })}
      >
        {({ isHovered }) => (
          <>
            {isHovered ? "Remove" : "Added"}
            {isHovered ? <XMark /> : <Check />}
          </>
        )}
      </Button>
    );
  }
  if (isLimitReached) {
    return (
      <Button
        size="sm"
        variant="purple"
        onPress={() => {
          closeModal();
          useAppStore.getState().setIsPlanCustomizationOpen(true);
        }}
      >
        Upgrade
      </Button>
    );
  }
  return (
    <Button
      size="sm"
      variant="green"
      isDisabled={isAddingKeyword}
      onPress={() => addKeyword(newKeywordData)}
    >
      Add
    </Button>
  );
}
