import { Modal as AriaModal, Dialog } from "react-aria-components";
import {
  ModalOverlay,
  Modal,
  DialogHeading,
  UnstyledButton,
} from "common/components/base";
import { clsx } from "clsx";
import { Drive } from "common/types.ts";

import XMark from "fontawesome/regular/xmark.svg?react";
import { useTwBreakpoint } from "common/hooks";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { addLeadingZero, formatDate } from "common/utils.ts";
import { useEffect, useState } from "react";

export default function DriveDetails({
  drive,
  onClose,
}: {
  drive: Drive | undefined;
  onClose: () => void;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const [cachedDrive, setCachedDrive] = useState<Drive>();

  useEffect(() => {
    if (drive) {
      setCachedDrive(drive);
    }
  }, [drive]);

  if (!cachedDrive) {
    return;
  }

  const { id, image_url, created_at, steps } = cachedDrive;

  function renderContent({ close }: { close: () => void }) {
    const driveDateTime = fromAbsolute(created_at, getLocalTimeZone());
    return (
      <>
        <div className="mb-4 mr-6 flex items-center justify-center lg:mb-6 lg:justify-between">
          <DialogHeading className="text-2xl font-semibold max-lg:my-1.5">
            Drive {id}
          </DialogHeading>
          <UnstyledButton onPress={close} className="group max-lg:hidden">
            <XMark className="size-6 fill-grey-600 group-data-[hovered]:fill-grey-900" />
          </UnstyledButton>
        </div>
        <div className="flex flex-col overflow-y-auto pr-6 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey-200">
          <img className="mb-4 rounded-2xl" src={image_url} alt="" />
          <div className="mb-4 flex justify-between">
            <span className="text-lg font-semibold lg:text-2xl lg:font-medium">
              Drive History
            </span>
            <span className="text-sm capitalize text-grey-500 lg:text-base">
              {formatDate(driveDateTime, "long", false)}
            </span>
          </div>
          <div className="flex flex-col">
            {steps.map(({ timestamp, instructions }) => {
              const stepDateTime = fromAbsolute(timestamp, getLocalTimeZone());
              return (
                <div className="group flex gap-x-5 lg:gap-x-8" key={timestamp}>
                  <div className="flex w-5 shrink-0 flex-col items-center">
                    <div className="mt-1.5 flex h-full flex-col items-center group-first:mt-px group-last:mt-px">
                      <div
                        className={clsx(
                          "size-2.5 shrink-0 rounded-full bg-grey-300",
                          "group-first:size-5 group-first:border-4 group-first:bg-white",
                          "group-last:size-5 group-last:border-4 group-last:border-green-500 group-last:bg-white",
                        )}
                      />
                      <div className="-mb-1.5 h-full w-px bg-grey-300 group-last:hidden" />
                    </div>
                  </div>
                  <div className="mb-7 flex grow items-center justify-between gap-x-2.5 group-first:items-start group-last:items-start lg:mb-8">
                    <span className="text-sm group-first:font-medium group-last:font-medium lg:text-base">
                      {instructions}
                    </span>
                    <span className="text-xs text-grey-500 group-first:font-medium group-first:text-grey-900 group-last:font-medium group-last:text-grey-900 lg:text-sm">
                      {addLeadingZero(stepDateTime.hour)}:
                      {addLeadingZero(stepDateTime.minute)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
  return isDesktop ? (
    <ModalOverlay
      isDismissable
      isOpen={!!drive}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
    >
      <AriaModal
        className={({ isExiting, isEntering }) =>
          clsx(
            "fixed inset-y-0 right-0 m-5 w-[500px]",
            isEntering && "animate-in slide-in-from-right",
            isExiting && "animate-out slide-out-to-right",
          )
        }
      >
        <Dialog className="flex h-full flex-col rounded-5xl bg-white p-6 pr-0.5 outline-none">
          {renderContent}
        </Dialog>
      </AriaModal>
    </ModalOverlay>
  ) : (
    <Modal
      className="flex-col"
      variant="grey"
      isOpen={!!drive}
      setIsOpen={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
    >
      {renderContent}
    </Modal>
  );
}
