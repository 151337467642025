import {
  BillingFrequency,
  OptionsSettingsItem,
  PaymentEstimate,
  PlanSettings,
  AllSettings,
} from "common/types.ts";
import usePaymentEstimate from "common/datahooks/usePaymentEstimate.ts";

import Coins from "fontawesome/solid/coins.svg?react";
import AlertMessage from "common/components/PlanCustomization/AlertMessage.tsx";
import { useParams } from "@tanstack/react-router";
import usePlace from "common/datahooks/usePlace.tsx";
import useSubscription from "common/datahooks/useSubscription.ts";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { formatDate } from "common/utils.ts";
import useNextPaymentEstimate from "common/datahooks/useNextPaymentEstimate.tsx";
import usePlanPricing from "common/datahooks/usePlanPricing.tsx";
import { Skeleton } from "common/components/base";

export default function PlanPrice({
  selectedPlanDetails,
  allSettings,
  billingFrequency,
  hasChanges,
}: {
  selectedPlanDetails: PlanSettings | undefined;
  allSettings: AllSettings | undefined;
  billingFrequency: BillingFrequency;
  hasChanges: boolean;
}) {
  const { placeId } = useParams({ from: "/$placeId" });
  const { place } = usePlace(placeId);
  const { subscription } = useSubscription(placeId);
  const { paymentEstimate } = usePaymentEstimate(
    placeId,
    billingFrequency,
    selectedPlanDetails,
  );
  const { nextPaymentEstimate } = useNextPaymentEstimate(placeId);
  const { planPricing } = usePlanPricing();

  const placeSettings = place?.settings;

  const pricingItems: {
    key: keyof PaymentEstimate;
    label: string;
    getValue: (planSettings: PlanSettings) => string | number;
  }[] = allSettings
    ? [
        {
          key: "grid_size",
          label: "Grid size",
          getValue: (planDetails) =>
            (
              allSettings.grid_size.options.find(
                ({ id }) => id === planDetails.grid_size,
              ) as OptionsSettingsItem["options"][number]
            ).value,
        },
        {
          key: "keyword_count",
          label: "Keywords",
          getValue: (planDetails) => planDetails.keyword_count,
        },
        {
          key: "schedule",
          label: "Frequency",
          getValue: (planDetails) =>
            `${planDetails.schedule.length} Day${planDetails.schedule.length > 1 ? "s" : ""}`,
        },
        {
          key: "drives",
          label: "Daily drives",
          getValue: (planDetails) => planDetails.drives,
        },
      ]
    : [];

  const nextBillingDate = paymentEstimate
    ? fromAbsolute(paymentEstimate.billing_date * 1000, getLocalTimeZone())
    : undefined;

  return (
    <>
      <div className="mx-2 mt-3 flex border-b border-b-grey-200 pb-2.5 text-sm">
        <span className="font-medium text-grey-500">Starter plan</span>
        <span className="ml-auto font-medium text-grey-500">
          Basic features
        </span>
        {planPricing && (
          <span className="flex w-14 items-center justify-end font-semibold">
            ${planPricing[billingFrequency] / 100}
          </span>
        )}
      </div>
      {pricingItems.some(
        ({ key }) => paymentEstimate && paymentEstimate[key] !== 0,
      ) && (
        <div className="mx-2 mt-2.5 flex flex-col gap-y-2.5 border-b border-b-grey-200 pb-2.5">
          {pricingItems.map(({ key, label, getValue }) => {
            const previousValue = placeSettings && getValue(placeSettings);
            const currentValue =
              selectedPlanDetails && getValue(selectedPlanDetails);
            const price = paymentEstimate && paymentEstimate[key] / 100;

            if (price) {
              return (
                <div key={label} className="flex text-sm">
                  <span className="font-medium text-grey-500">{label}</span>
                  <span className="ml-auto font-medium text-grey-500">
                    {previousValue &&
                      currentValue !== previousValue &&
                      `${previousValue} -> `}
                    {currentValue}
                  </span>
                  <div className="flex w-14 items-center justify-end font-semibold">
                    ${price}
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}

      <div className="mx-2 mb-3 mt-2.5 flex items-center justify-between">
        <span className="text-sm font-medium text-grey-500">
          Subscription price:
        </span>
        <div className="flex items-center gap-x-1">
          {subscription &&
            paymentEstimate &&
            nextPaymentEstimate &&
            nextPaymentEstimate.total !== paymentEstimate.total && (
              <span className="text-sm font-medium text-grey-500">
                ${nextPaymentEstimate.total / 100}
                {billingFrequency !== subscription.period &&
                  `/${subscription.period}`}{" "}
                {"->"}
              </span>
            )}
          {paymentEstimate ? (
            <span className="text-2xl font-semibold">
              ${paymentEstimate.total / 100}
            </span>
          ) : (
            <Skeleton className="h-8 w-14 rounded-full" />
          )}
          <span className="text-sm font-medium text-grey-500">
            / {billingFrequency}
          </span>
        </div>
      </div>
      {nextBillingDate ? (
        <p className="text-sm">
          {subscription?.status === "in_trial" && !hasChanges ? (
            <>
              Your{" "}
              <span className="font-semibold text-purple-500">free trial</span>{" "}
              ends on{" "}
              <span className="font-semibold capitalize text-purple-500">
                {formatDate(nextBillingDate, "long", false)}
              </span>
              , after that you will be charged per your subscription price
            </>
          ) : (
            <>
              Your next billing date will be{" "}
              <span className="font-semibold">
                {formatDate(nextBillingDate, "none", false)},
              </span>{" "}
              {nextBillingDate.year}
            </>
          )}
        </p>
      ) : (
        <Skeleton className="h-5 w-48 rounded-full" />
      )}
      <AlertMessage
        isTrial={!!subscription && subscription.status === "in_trial"}
        hasTokens={!!paymentEstimate?.tokens}
      />
      <div className="mt-auto">
        {paymentEstimate && (
          <div className="my-4 flex items-center justify-between pt-4 max-lg:border-t max-lg:border-t-grey-300 lg:mt-auto">
            <span className="text-sm font-medium">
              {paymentEstimate?.tokens ? "Total" : "To pay now:"}
            </span>
            <div className="flex items-center gap-x-1 text-2xl font-semibold">
              {paymentEstimate.tokens
                ? `+${paymentEstimate.tokens / 100}`
                : `$${paymentEstimate.pay_now / 100}`}
              {!!paymentEstimate.tokens && (
                <Coins className="size-6 fill-purple-500" />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
