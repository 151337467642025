import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import useCustomer from "@/src/common/datahooks/useCustomer.ts";
import { Input, FieldError } from "common/components/base";
import { TextField } from "react-aria-components";
import useCustomerMutations from "common/datahooks/useCustomerMutations.tsx";
import { Controller, useForm } from "react-hook-form";
import { EmailSchema, EmailSchemaType } from "common/schemas.tsx";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useEffect } from "react";

export default function Email({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  const { customer } = useCustomer();
  const { updateCustomer, isUpdatingCustomer } = useCustomerMutations();
  const { control, handleSubmit, reset } = useForm<EmailSchemaType>({
    resolver: valibotResolver(EmailSchema),
  });

  useEffect(() => {
    reset();
  }, [isEditing]);

  function onSubmit({ email }: EmailSchemaType) {
    updateCustomer({ email }).then(toggleIsEditing);
  }

  return (
    <SettingsItem
      label="Email"
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isUpdatingCustomer}
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      editingComponent={
        <Controller
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error },
          }) => (
            <TextField
              aria-label="email address"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              validationBehavior="aria"
              isInvalid={invalid}
            >
              <Input
                ref={ref}
                size="md"
                className="w-full lg:max-w-40 xl:max-w-72"
                autoFocus
                placeholder="Email address"
              />
              <FieldError size="md">{error?.message}</FieldError>
            </TextField>
          )}
          name="email"
        />
      }
    >
      {customer ? (
        <span className="text-sm font-medium">{customer.email}</span>
      ) : (
        <span className="text-sm">Loading...</span>
      )}
    </SettingsItem>
  );
}
