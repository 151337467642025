import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import useCustomer from "@/src/common/datahooks/useCustomer.ts";
import { Input, FieldError } from "common/components/base";
import useCustomerMutations from "common/datahooks/useCustomerMutations.tsx";
import { TextField } from "react-aria-components";
import { Controller, useForm } from "react-hook-form";
import { NameSchema, NameSchemaType } from "common/schemas";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useEffect } from "react";

export default function FullName({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  const { customer } = useCustomer();
  const { updateCustomer, isUpdatingCustomer } = useCustomerMutations();
  const { control, handleSubmit, reset } = useForm<NameSchemaType>({
    resolver: valibotResolver(NameSchema),
  });

  useEffect(() => {
    reset();
  }, [isEditing]);

  function onSubmit({ firstName, lastName }: NameSchemaType) {
    updateCustomer({ first_name: firstName, last_name: lastName }).then(
      toggleIsEditing,
    );
  }

  return (
    <SettingsItem
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      label="Full Name"
      isLoading={isUpdatingCustomer}
      onSubmit={handleSubmit(onSubmit)}
      editingComponent={
        <div className="flex flex-col gap-y-4">
          <Controller
            control={control}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { invalid, error },
            }) => (
              <TextField
                isRequired
                aria-label="first name"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                validationBehavior="aria"
                isInvalid={invalid}
              >
                <Input
                  ref={ref}
                  size="md"
                  className="w-full lg:max-w-40 xl:max-w-72"
                  placeholder="First name"
                  autoFocus
                />
                <FieldError size="md">{error?.message}</FieldError>
              </TextField>
            )}
            name="firstName"
          />
          <Controller
            control={control}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { invalid, error },
            }) => (
              <TextField
                isRequired
                aria-label="last name"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                validationBehavior="aria"
                isInvalid={invalid}
              >
                <Input
                  ref={ref}
                  size="md"
                  className="w-full lg:max-w-40 xl:max-w-72"
                  placeholder="Last name"
                  autoFocus
                />
                <FieldError size="md">{error?.message}</FieldError>
              </TextField>
            )}
            name="lastName"
          />
        </div>
      }
    >
      {customer ? (
        <span className="text-sm font-medium">
          {customer.first_name} {customer.last_name}
        </span>
      ) : (
        <span className="text-sm">Loading...</span>
      )}
    </SettingsItem>
  );
}
