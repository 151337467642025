import {
  Cell,
  CellProps,
  Column,
  ColumnProps,
  TableHeader as AriaTableHeader,
  TableHeaderProps,
  Row,
  RowProps,
  Collection,
  TableBody as AriaTableBody,
  TableBodyProps,
} from "react-aria-components";
import { clsx } from "clsx";

import ArrowUpArrowDown from "fontawesome/regular/arrow-up-arrow-down.svg?react";
import ArrowUp from "fontawesome/regular/arrow-up.svg?react";
import ArrowDown from "fontawesome/regular/arrow-down.svg?react";

export function TableCell({ children, className, ...rest }: CellProps) {
  return (
    <Cell
      className={clsx(
        "text-sm first:rounded-l-full first:pl-3 last:rounded-r-full last:pr-3",
        className,
      )}
      {...rest}
    >
      {children}
    </Cell>
  );
}
export function TableColumn({ children, className, ...rest }: ColumnProps) {
  return (
    <Column
      className={clsx(
        "text-nowrap px-0 py-0 text-left text-xs font-medium text-grey-500 first:pl-3 last:pr-3",
        className,
      )}
      {...rest}
    >
      {(renderProps) => {
        const { allowsSorting, sortDirection } = renderProps;
        let sortArrow;

        if (allowsSorting) {
          sortArrow = <ArrowUpArrowDown className="size-3 fill-grey-500" />;
          if (sortDirection) {
            sortArrow =
              sortDirection === "ascending" ? (
                <ArrowUp className="size-3 fill-grey-500" />
              ) : (
                <ArrowDown className="size-3 fill-grey-500" />
              );
          }
        }

        return (
          <>
            <div className="flex h-8 items-center gap-x-2">
              {typeof children === "function"
                ? children(renderProps)
                : children}
              {sortArrow}
            </div>
            <span className="block border-b border-b-grey-200" />
          </>
        );
      }}
    </Column>
  );
}
export function TableHeader<T extends object>({
  children,
  columns,
  className,
}: TableHeaderProps<T>) {
  return (
    <AriaTableHeader className={clsx("after:table-row after:h-4", className)}>
      <Collection items={columns}>{children}</Collection>
    </AriaTableHeader>
  );
}

export function TableRow<T extends object>({
  children,
  className,
  columns,
  ...rest
}: RowProps<T>) {
  return (
    <Row
      className={clsx(
        "rounded-full border-b border-none border-b-red-200 transition-[background] data-[hovered]:bg-grey-50",
        className,
      )}
      {...rest}
    >
      <Collection items={columns}>
        {typeof children === "function"
          ? (column) => children(column)
          : children}
      </Collection>
    </Row>
  );
}

export function TableBody<T extends object>({
  children,
  className,
}: TableBodyProps<T>) {
  return (
    <AriaTableBody<T> className={clsx("", className)}>
      {typeof children === "function" ? (row) => children(row) : children}
    </AriaTableBody>
  );
}
