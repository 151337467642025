import { SubscriptionStatus } from "common/types.ts";
import { Badge } from "common/components/base";
import BadgeCheck from "fontawesome/solid/badge-check.svg?react";

export default function SubscriptionBadge({
  subscriptionStatus,
  isDemo,
}: {
  subscriptionStatus: SubscriptionStatus;
  isDemo: boolean;
}) {
  if (isDemo) {
    return (
      <Badge variant="greenFull" size="sm">
        Demo
      </Badge>
    );
  }
  if (subscriptionStatus === "in_trial") {
    return (
      <Badge variant="purple" size="sm">
        Free trial
      </Badge>
    );
  }
  if (subscriptionStatus === "active") {
    return (
      <Badge variant="purpleFull" size="sm">
        <BadgeCheck />
        Pro Plan
      </Badge>
    );
  }
  if (subscriptionStatus === "cancelled") {
    return (
      <Badge variant="disabled" size="sm">
        Cancelled
      </Badge>
    );
  }
}
