import "common/helpers/instrument.tsx";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./globals.css";
import { RouterProvider, createRouter } from "@tanstack/react-router";

import { routeTree } from "./routeTree.gen";
import { UserbackProvider } from "@userback/react";
import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";

const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserbackProvider
        token={import.meta.env.VITE_USERBACK_TOKEN}
        options={{ is_live: import.meta.env.VITE_VERCEL_ENV === "production" }}
      >
        <RouterProvider router={router} />
      </UserbackProvider>
    </QueryClientProvider>
  </StrictMode>,
);
