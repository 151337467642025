import {
  TableBody,
  TableHeader,
  TableColumn,
  TableRow,
  TableCell,
  Button,
  Pagination,
} from "common/components/base";
import { Table } from "react-aria-components";

import FileExport from "fontawesome/regular/file-export.svg?react";
import { usePagination, useTwBreakpoint } from "common/hooks";
import usePlaceInvoices from "common/datahooks/usePlaceInvoices.ts";
import EmptyPlaceholder from "common/components/base/EmptyPlaceholder/EmptyPlaceholder.tsx";
import NoInvoicesImage from "@/src/assets/empty-placeholder/no-invoices.svg?react";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { formatDate } from "common/utils.ts";
import { Invoice } from "common/types.ts";
import { clsx } from "clsx";
import useInvoiceMutations from "common/datahooks/useInvoiceMutations.tsx";
import { useParams } from "@tanstack/react-router";

const billingStatuses: Record<
  Invoice["status"],
  { label: string; textClassName: string }
> = {
  paid: { label: "Paid", textClassName: "text-green-600" },
  not_paid: { label: "Unpaid", textClassName: "text-red-500" },
  pending: { label: "Pending", textClassName: "text-red-500" },
  payment_due: { label: "Payment due", textClassName: "text-red-500" },
};

export default function Invoices() {
  const isDesktop = useTwBreakpoint("lg");
  const { placeId } = useParams({ from: "/$placeId" });
  const { invoices } = usePlaceInvoices(placeId);
  const { downloadInvoice } = useInvoiceMutations();
  const { pageItems, paginationComponentProps, isPaginationVisible } =
    usePagination(5, invoices);

  function openInvoice(invoiceId: string) {
    downloadInvoice({ invoiceId }).then((value) => {
      window.open(value);
    });
  }

  let mainComponent;
  if (invoices && !invoices.length) {
    mainComponent = (
      <EmptyPlaceholder
        title="You don’t have any invoices yet"
        subtitle="It looks like no invoices have been generated for you so far. As soon as one becomes available, you’ll find it here"
        image={<NoInvoicesImage />}
      />
    );
  } else if (isDesktop) {
    mainComponent = (
      <Table>
        <TableHeader>
          <TableColumn isRowHeader>Date</TableColumn>
          <TableColumn>Plan</TableColumn>
          <TableColumn>Amount</TableColumn>
          <TableColumn>Status</TableColumn>
          <TableColumn className="w-1/12"></TableColumn>
        </TableHeader>
        <TableBody items={pageItems}>
          {pageItems.map(({ id, date, status, total }) => {
            const zonedDateTime = fromAbsolute(date * 1000, getLocalTimeZone());
            return (
              <TableRow key={id} className="text-sm font-semibold">
                <TableCell>{formatDate(zonedDateTime, "none", true)}</TableCell>
                <TableCell>Pro Plan</TableCell>
                <TableCell>${total / 100}</TableCell>
                <TableCell className={billingStatuses[status].textClassName}>
                  {billingStatuses[status].label}
                </TableCell>
                <TableCell>
                  <Button
                    size="lg"
                    variant="transparent"
                    isIconOnly
                    onPress={() => openInvoice(id)}
                  >
                    <FileExport />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  } else {
    mainComponent = (
      <div className="mx-2 flex flex-col gap-y-3">
        {pageItems.map(({ id, date, status, total }) => {
          const zonedDateTime = fromAbsolute(date * 1000, getLocalTimeZone());
          return (
            <div
              key={id}
              className="flex flex-col gap-y-2.5 rounded-3xl bg-white p-3 font-semibold"
            >
              <div className="flex items-center justify-between">
                <span className="text-xs">Pro plan</span>
                <Button
                  size="sm"
                  variant="stroke"
                  isIconOnly
                  onPress={() => openInvoice(id)}
                >
                  <FileExport />
                </Button>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm">
                  {formatDate(zonedDateTime, "none", true)}
                </span>
                <span
                  className={clsx(
                    "text-sm",
                    billingStatuses[status].textClassName,
                  )}
                >
                  {billingStatuses[status].label}
                </span>
                <span>${total / 100}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-4xl bg-grey-50 lg:rounded-7xl">
      <h3 className="mx-3 my-4 text-lg font-semibold lg:m-6 lg:mb-5 lg:text-xl">
        Latest invoices
      </h3>
      <div className="flex flex-col rounded-4xl pb-2 lg:rounded-7xl lg:bg-white lg:p-6">
        {mainComponent}
        {isPaginationVisible && (
          <Pagination
            {...paginationComponentProps}
            size="sm"
            className="mx-auto mt-3"
          />
        )}
      </div>
    </div>
  );
}
