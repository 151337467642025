import { Drive } from "common/types.ts";
import { get } from "common/helpers/HTTP.ts";
import { useQuery } from "@tanstack/react-query";
import { dummyDrives, getDummyPlace } from "@/src/dummyGenerator.ts";
import { getLocalTimeZone, today } from "@internationalized/date";

type RequestParams = { page: number; pageSize: number; date?: string };
async function getDrives(
  placeId: string,
  { page, pageSize, date }: RequestParams,
): Promise<{ drives: Drive[]; pageCount: number }> {
  if (getDummyPlace(placeId)) {
    return date === today(getLocalTimeZone()).toString()
      ? { drives: dummyDrives, pageCount: 0 }
      : { drives: [], pageCount: 0 };
  }
  const searchParams = new URLSearchParams();

  searchParams.set("page", page.toString());
  searchParams.set("page_size", pageSize.toString());

  if (date) {
    searchParams.set("date", date);
  }
  const { drives, page_count } = await get(
    `v1/places/${placeId}/drives?${searchParams.toString()}`,
  );
  return { drives, pageCount: page_count };
}
export default function useDrives(placeId: string, params: RequestParams) {
  const { data, isFetching: isLoadingDrives } = useQuery({
    queryKey: ["drives", params],
    queryFn: () => getDrives(placeId, params),
    placeholderData: (prev) => prev,
  });
  return {
    drives: data?.drives,
    pageCount: data ? data.pageCount : 0,
    isLoadingDrives,
  };
}
