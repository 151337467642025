import { Coordinates, GridPoint as GridPointType } from "common/types.ts";
import { getRankColorClassnames, getRankLabel } from "common/utils.ts";
import { clsx } from "clsx";
import mapboxgl, { Popup } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

export default function GridTooltip({
  location,
  isOpen,
  map,
  ...rest
}: (Pick<GridPointType, "places"> | { text: string }) & {
  location: Coordinates;
  isOpen: boolean;
  map: mapboxgl.Map;
}) {
  const container = useMemo(() => {
    const el = document.createElement("div");
    el.className =
      "flex flex-col space-y-2.5 rounded-3xl bg-white px-4 pb-5 pt-3.5 shadow-lg";
    return el;
  }, []);

  const popup: Popup = useMemo(() => {
    const pop = new mapboxgl.Popup({
      offset: 36,
      closeButton: false,
      closeOnClick: false,
      className: "opacity-0",
    });
    pop.setLngLat({
      lng: location.longitude,
      lat: location.latitude,
    });

    return pop;
  }, []);

  useEffect(() => {
    popup.setDOMContent(container).addTo(map);
    return () => {
      popup.remove();
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      popup.addClassName("opacity-100");
      popup.addClassName("transition-[opacity]");
    } else {
      popup.removeClassName("opacity-100");
    }
  }, [isOpen]);

  return createPortal(
    <>
      {"text" in rest ? (
        <span className="text-sm font-normal text-grey-900">{rest.text}</span>
      ) : (
        rest.places.map(({ rank, name, owner }) => {
          return (
            <div className="flex items-center gap-x-1" key={name}>
              <div
                className={clsx(
                  "flex size-7 shrink-0 items-center justify-center rounded-full text-xs font-medium text-grey-900",
                  getRankColorClassnames(rank).bg,
                )}
              >
                {getRankLabel(rank)}
              </div>
              <span
                className={clsx(
                  "text-sm text-grey-900",
                  owner ? "font-semibold" : "font-medium",
                )}
                key={name}
              >
                {name}
              </span>
            </div>
          );
        })
      )}
    </>,
    container,
  );
}
