import {
  DropdownBox,
  DropdownBoxItem,
  Label,
  NumberInput,
  Select,
} from "common/components/base";
import { PlanSettings, AllSettings } from "common/types.ts";
import { clsx } from "clsx";
import { cloneElement, JSX } from "react";
import { useLabel } from "react-aria";
import { WeekdaySelector } from "common/components/WeekdaySelector";

function PlanItem({
  label,
  description,
  component,
  isColumn = false,
}: {
  label: string;
  description: string | undefined;
  component: JSX.Element;
  isColumn?: boolean;
}) {
  const { labelProps, fieldProps } = useLabel({ label });

  return (
    <div
      className={clsx(
        "mb-4 flex justify-between border-b border-b-grey-200 pb-4 last:mb-0 last:border-none",
        isColumn && "flex-col gap-2",
      )}
    >
      <div className="flex flex-col gap-y-1">
        <Label size="lg" {...labelProps}>
          {label}
        </Label>
        <span className="ml-1 text-xs font-medium text-grey-500">
          {description}
        </span>
      </div>
      {cloneElement(component, fieldProps)}
    </div>
  );
}
export default function PlanDetailsSelection({
  selectedPlanDetails,
  setSelectedPlanDetails,
  allSettings,
}: {
  selectedPlanDetails: PlanSettings | undefined;
  setSelectedPlanDetails: (planSettings: PlanSettings) => void;
  allSettings: AllSettings | undefined;
}) {
  function setValue<T extends keyof PlanSettings>(
    key: T,
    value: PlanSettings[T],
  ) {
    if (selectedPlanDetails) {
      setSelectedPlanDetails({ ...selectedPlanDetails, [key]: value });
    }
  }

  const planItems = [
    {
      label: "Grid view",
      description:
        allSettings && `$${allSettings.grid_size.unit_price / 100} / grid size`,
      component: (
        <Select
          size="lg"
          className="w-40"
          selectedKey={selectedPlanDetails?.grid_size}
          onSelectionChange={(gridSizeId) =>
            setValue("grid_size", gridSizeId as string)
          }
        >
          <DropdownBox size="lg">
            {(allSettings ? allSettings.grid_size.options : []).map(
              ({ id, label }) => (
                <DropdownBoxItem key={id} id={id} size="lg" title={label} />
              ),
            )}
          </DropdownBox>
        </Select>
      ),
    },
    {
      label: "Keywords",
      description:
        allSettings &&
        `$${allSettings.keyword_count.unit_price / 100} / keyword`,
      component: (
        <NumberInput
          size="lg"
          className="w-40"
          minValue={allSettings?.keyword_count.min}
          maxValue={allSettings?.keyword_count.max}
          step={allSettings?.keyword_count.unit}
          value={selectedPlanDetails?.keyword_count}
          onChange={(value) => setValue("keyword_count", value)}
        />
      ),
    },
    {
      label: "Frequency",
      description:
        allSettings &&
        `$${allSettings.schedule.unit_price / 100} / daily update`,
      isColumn: true,
      component: (
        <WeekdaySelector
          selectedWeekdays={selectedPlanDetails?.schedule}
          setSelectedWeekdays={(selectedWeekdays) =>
            setValue("schedule", selectedWeekdays)
          }
        />
      ),
    },
    {
      label: "Daily drives",
      description:
        allSettings &&
        `$${allSettings.drives.unit_price / 100} / ${allSettings.drives.unit} daily drives`,
      component: (
        <NumberInput
          size="lg"
          className="w-40"
          minValue={allSettings?.drives.min}
          maxValue={allSettings?.drives.max}
          step={allSettings?.drives.unit}
          value={selectedPlanDetails?.drives}
          onChange={(value) => setValue("drives", value)}
        />
      ),
    },
  ];
  return planItems.map(({ component, description, label, isColumn }) => (
    <PlanItem
      key={label}
      label={label}
      component={component}
      description={description}
      isColumn={isColumn}
    />
  ));
}
