import { createFileRoute, Outlet } from "@tanstack/react-router";
import { ButtonLink, UserAvatar } from "common/components/base";
import Logo from "@/src/assets/logo.svg?react";
import ArrowLeft from "fontawesome/regular/arrow-left.svg?react";
import XMark from "fontawesome/regular/xmark.svg?react";

import useCustomer from "@/src/common/datahooks/useCustomer.ts";
import usePlaces from "common/datahooks/usePlaces.ts";
import { useTwBreakpoint } from "common/hooks";
import { useIntercom } from "react-use-intercom";
import { useEffect } from "react";

export const Route = createFileRoute("/_layout")({
  component: Layout,
});
const navItems = [
  { label: "Account", path: "/account" },
  { label: "GBP profiles", path: "/profiles" },
];
function Layout() {
  const isDesktop = useTwBreakpoint("lg");
  const { update } = useIntercom();
  const { customer } = useCustomer();
  const { places } = usePlaces();

  useEffect(() => {
    if (!isDesktop) {
      update({ hideDefaultLauncher: false });
      return () => update({ hideDefaultLauncher: true });
    }
  }, [isDesktop]);

  let goBackPlaceId;
  if (localStorage.getItem("")) {
    goBackPlaceId = localStorage.getItem("selectedPlaceId");
  } else if (places) {
    goBackPlaceId = places[0].id;
  }

  return (
    <>
      <div className="fixed -z-base h-dvh w-full bg-grey-100 bg-[radial-gradient(57.48%_70.31%_at_50%_87.27%,rgba(144,97,249,0.2)_0%,rgba(144,97,249,0)_100%)]" />
      <div className="flex h-dvh flex-1 flex-col px-2 py-4 lg:p-5">
        <div className="mb-6 flex flex-col gap-6 lg:mb-3 lg:flex-col-reverse lg:gap-4">
          <div className="flex items-center justify-between gap-x-1 lg:flex-row-reverse lg:justify-end">
            {customer && (
              <div className="flex items-center rounded-full max-lg:pl-2 lg:bg-grey-50 lg:p-1">
                <UserAvatar email={customer.email} size="sm" />
                <div className="flex flex-col px-4 lg:gap-y-1">
                  <span className="text-base font-medium">
                    {customer.first_name} {customer.last_name}
                  </span>
                  <span className="text-xs font-medium text-grey-500">
                    {customer.email}
                  </span>
                </div>
              </div>
            )}
            {goBackPlaceId && (
              <ButtonLink
                to="/$placeId/home"
                params={{ placeId: goBackPlaceId }}
                size="lg"
                variant="stroke"
                isIconOnly
              >
                <XMark className="lg:hidden" />
                <ArrowLeft className="max-lg:hidden" />
              </ButtonLink>
            )}
          </div>
          <div className="relative flex gap-x-3 max-lg:pl-2 lg:justify-center">
            <div className="absolute left-0 flex size-12 items-center justify-center rounded-full bg-green-300 max-lg:hidden">
              <Logo className="size-8" />
            </div>
            {navItems.map(({ label, path }) => {
              return (
                <ButtonLink
                  key={label}
                  to={`/${path}`}
                  size="lg"
                  activeProps={{
                    variant: "black",
                  }}
                  inactiveProps={{
                    variant: "transparent",
                  }}
                >
                  {label}
                </ButtonLink>
              );
            })}
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
}
