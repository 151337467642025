import { useSelectedPlace } from "@/src/contexts.ts";
import { clsx } from "clsx";
import useAllSettings from "common/datahooks/useAllSettings.ts";
import { metersToFeet } from "common/utils.ts";
import useCustomer from "common/datahooks/useCustomer.ts";

export default function SettingsMap({
  selectedTab,
  gridSize = 0,
  drivesRadius = 0,
  gridDistance = 0,
}: {
  selectedTab: "drives" | "grid";
  gridSize: number | undefined;
  gridDistance: number | undefined;
  drivesRadius: number | undefined;
}) {
  const {
    selectedPlace: {
      name,
      location: { longitude, latitude },
    },
  } = useSelectedPlace();
  const { allSettings } = useAllSettings();
  const { customer } = useCustomer();

  let gridPointSize = 40;
  let gridGapSize = 28;
  let zoomLevel = 14;

  if (gridSize > 11) {
    gridPointSize = 20;
    gridGapSize = 12;
  } else if (gridSize > 7) {
    gridPointSize = 30;
    gridGapSize = 14;
  }
  if (gridSize > 11 || gridDistance > 800) {
    zoomLevel = 12;
  } else if (gridSize > 7 || gridDistance > 500) {
    zoomLevel = 13;
  }

  const searchParams = new URLSearchParams();
  searchParams.set("access_token", import.meta.env.VITE_MAPBOX_TOKEN);

  const mapSrc = `https://api.mapbox.com/styles/v1/mapbox/light-v11/static/${[longitude, latitude, zoomLevel].join(",")}/600x600?${searchParams.toString()}`;

  const gridPoints = [];
  if (gridSize) {
    const isGridSettings = selectedTab === "grid";

    const gridDistanceComponent = isGridSettings && (
      <div className="absolute z-modal flex items-center justify-center rounded-full bg-purple-500 px-2 text-sm text-white">
        {customer && customer.preferred_unit === "Meters"
          ? gridDistance
          : metersToFeet(gridDistance)}
      </div>
    );

    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        gridPoints.push(
          <div
            key={`point-${i}-${j}`}
            className={clsx(
              "flex size-5 items-center justify-center rounded-full bg-green-300",
              isGridSettings ? "bg-green-300" : "bg-grey-400",
            )}
            style={{
              width: `${gridPointSize}px`,
              height: `${gridPointSize}px`,
            }}
          >
            {isGridSettings && (
              <div className="size-[5px] border border-purple-500 bg-white" />
            )}
          </div>,
        );
        if (j !== gridSize - 1) {
          gridPoints.push(
            <div
              key={`horizontal-divider-${i}-${j}`}
              className="relative flex items-center justify-center"
            >
              {i === 0 && j === gridSize - 2 && gridDistanceComponent}
              <div
                className={clsx(
                  "z-base h-px self-center border-t border-dashed border-purple-500",
                  !isGridSettings && "invisible",
                )}
                style={{
                  marginLeft: `-${gridPointSize / 2}px`,
                  marginRight: `-${gridPointSize / 2}px`,
                  width: `${gridGapSize + gridPointSize}px`,
                }}
              />
            </div>,
          );
        }
      }
      if (i !== gridSize - 1) {
        for (let j = 0; j < gridSize; j++) {
          gridPoints.push(
            <div
              key={`vertical-divider-${i}-${j}`}
              className="relative flex items-center justify-center"
            >
              {j === gridSize - 1 && i === 0 && gridDistanceComponent}
              <div
                className={clsx(
                  "z-base w-px justify-self-center border-r border-dashed border-purple-500",
                  !isGridSettings && "invisible",
                )}
                style={{
                  marginTop: `-${gridPointSize / 2}px`,
                  marginBottom: `-${gridPointSize / 2}px`,
                  height: `${gridGapSize + gridPointSize}px`,
                }}
              />
            </div>,
          );
          if (j !== gridSize - 1) {
            gridPoints.push(<div key={`empty-${i}-${j}`} />);
          }
        }
      }
    }
  }

  const drivesRadiusSize = allSettings
    ? 10 +
      ((90 - 10) /
        (allSettings.drives_radius.max - allSettings.drives_radius.min)) *
        (drivesRadius - allSettings.drives_radius.min)
    : 0;

  return (
    <div className="relative flex w-full overflow-hidden rounded-4xl bg-grey-200 lg:size-[500px] lg:rounded-5xl">
      <img alt={`static map of ${name}`} src={mapSrc} />
      <div
        className={clsx(
          "absolute inset-0 m-auto size-72 rounded-full border",
          selectedTab === "drives"
            ? "border-green-500 bg-green-500/20"
            : "border-grey-500 bg-grey-500/20",
        )}
        style={{
          width: `${drivesRadiusSize}%`,
          height: `${drivesRadiusSize}%`,
        }}
      />
      <div
        className="absolute inset-0 grid content-center justify-center"
        style={{
          gridTemplateColumns: `repeat(${gridSize + gridSize - 1}, min-content)`,
          gridTemplateRows: `repeat(${gridSize + gridSize - 1}, min-content)`,
        }}
      >
        {gridPoints}
      </div>
    </div>
  );
}
