import { JSX, useState } from "react";
import { Button } from "common/components/base";

export default function MobileLayout({
  customizeComponent,
  summaryComponent,
  paymentMethodComponent,
  successComponent,
}: {
  customizeComponent: JSX.Element;
  summaryComponent: JSX.Element;
  paymentMethodComponent: JSX.Element | undefined;
  successComponent: JSX.Element | undefined;
}) {
  const [isSummaryPage, setIsSummaryPage] = useState(false);

  let mainComponent;
  if (isSummaryPage) {
    mainComponent = summaryComponent;
  } else {
    mainComponent = (
      <>
        {customizeComponent}
        <Button
          variant="black"
          size="lg"
          className="mt-auto"
          onPress={() => setIsSummaryPage(true)}
        >
          Next
        </Button>
      </>
    );
  }
  if (paymentMethodComponent) {
    mainComponent = paymentMethodComponent;
  }
  if (successComponent) {
    mainComponent = successComponent;
  }
  return <div className="flex flex-1 flex-col px-5 pb-2">{mainComponent}</div>;
}
