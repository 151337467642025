import { CARD_ICONS } from "@/src/constants.tsx";
import { clsx } from "clsx";

import Paypal from "fontawesome/brands/cc-paypal.svg?react";
import CircleCheck from "fontawesome/solid/circle-check.svg?react";
import { PaymentSource } from "common/types.ts";
import { Skeleton, UnstyledButton } from "common/components/base";

export default function PaymentSourcesList({
  selectedPaymentSourceId,
  setSelectedPaymentSourceId,
  paymentSources,
}: {
  selectedPaymentSourceId: string | undefined;
  setSelectedPaymentSourceId: (paymentSourceId: string) => void;
  paymentSources: PaymentSource[] | undefined;
}) {
  return paymentSources
    ? paymentSources.map((paymentSource) => {
        const { id, type } = paymentSource;
        const isSelected = selectedPaymentSourceId === id;

        const Icon =
          type === "paypal_express_checkout"
            ? Paypal
            : CARD_ICONS[paymentSource.card.brand];
        return (
          <UnstyledButton
            key={id}
            onPress={() => setSelectedPaymentSourceId(id)}
            className={clsx(
              "flex items-center gap-x-3 rounded-3xl border px-3.5 py-5 md:px-3",
              isSelected
                ? "border-purple-300 bg-purple-50"
                : "border-grey-200 data-[hovered]:border-purple-300 data-[hovered]:bg-purple-50",
            )}
          >
            <Icon className="size-6" />
            <span className="text-sm font-semibold">
              {type === "card"
                ? `Card ending in ${paymentSource.card.last4}`
                : `Paypal ${paymentSource.paypal.email}`}
            </span>
            {isSelected && (
              <CircleCheck className="ml-auto size-4 fill-purple-500" />
            )}
          </UnstyledButton>
        );
      })
    : new Array(4).fill(undefined).map((_, index) => (
        <div
          className="flex items-center gap-x-3 rounded-3xl border border-grey-200 px-3.5 py-5 md:px-3"
          key={index}
        >
          <Skeleton className="size-6 rounded-md" />
          <Skeleton className="h-5 w-36 rounded-full" />
        </div>
      ));
}
