import { useMutation } from "@tanstack/react-query";
import { get } from "common/helpers/HTTP.ts";

async function downloadInvoiceRequest(invoiceId: string): Promise<string> {
  return (await get(`v1/billing/invoices/${invoiceId}/download`)).link;
}
export default function useInvoiceMutations() {
  const { mutateAsync: downloadInvoice, isPending: isDownloadingInvoice } =
    useMutation({
      mutationFn: ({ invoiceId }: { invoiceId: string }) =>
        downloadInvoiceRequest(invoiceId),
    });
  return {
    downloadInvoice,
    isDownloadingInvoice,
  };
}
