import { Badge } from "common/components/base";
import { metersToFeet } from "common/utils.ts";

import Ruler from "fontawesome/solid/ruler-horizontal.svg?react";
import Cars from "fontawesome/solid/cars.svg?react";
import useCustomer from "common/datahooks/useCustomer.ts";
import { Snapshot } from "common/types.ts";

export default function SnapshotBadges({
  selectedSnapshot,
}: {
  selectedSnapshot: Snapshot | undefined;
}) {
  const { customer } = useCustomer();

  if (selectedSnapshot && customer) {
    return (
      <>
        <Badge variant="grey" size="md">
          <Ruler />
          Grid distance:{" "}
          {customer.preferred_unit === "Feet"
            ? `${metersToFeet(selectedSnapshot.grid_distance)}ft`
            : `${selectedSnapshot.grid_distance}m`}
        </Badge>
        <Badge variant="grey" size="md">
          <Cars />
          Daily drives: {selectedSnapshot.completed_drives}/
          {selectedSnapshot.drives}
        </Badge>
      </>
    );
  }
  return (
    <>
      <Badge variant="skeleton" size="md" />
      <Badge variant="skeleton" size="md" />
    </>
  );
}
