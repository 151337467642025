import {
  Slider,
  Select,
  DropdownBoxItem,
  DropdownBox,
  NumberInput,
} from "common/components/base";
import { WeekdaySelector } from "common/components/WeekdaySelector";
import SettingsItem from "common/components/GridAndDrivesSettings/SettingsItem";
import useAllSettings from "common/datahooks/useAllSettings";
import { PaidAndFreePlaceSettings } from "common/types.ts";
import useCustomer from "common/datahooks/useCustomer.ts";
import { feetToMeters, metersToFeet } from "common/utils.ts";

export default function GridSettings({
  settings,
  setSettingsValue,
}: {
  settings: PaidAndFreePlaceSettings | undefined;
  setSettingsValue: <T extends keyof PaidAndFreePlaceSettings>(
    key: T,
    value: PaidAndFreePlaceSettings[T],
  ) => void;
}) {
  const { customer } = useCustomer();
  const { allSettings } = useAllSettings();

  const isMeters = customer && customer.preferred_unit === "Meters";

  const settingsItems = [
    {
      label: "Grid size",
      description:
        allSettings && `$${allSettings.grid_size.unit_price / 100} / grid size`,
      component: (
        <Select
          size="lg"
          placeholder="Select grid size"
          selectedKey={settings?.grid_size}
          onSelectionChange={(key) => {
            setSettingsValue("grid_size", key as string);
          }}
        >
          <DropdownBox size="lg">
            {(allSettings ? allSettings.grid_size.options : []).map(
              ({ id, label }) => (
                <DropdownBoxItem key={id} id={id} size="lg" title={label} />
              ),
            )}
          </DropdownBox>
        </Select>
      ),
      isPremium: true,
    },
    {
      label: "Frequency",
      description:
        allSettings &&
        `$${allSettings.schedule.unit_price / 100} / daily update`,
      component: (
        <WeekdaySelector
          setSelectedWeekdays={(selectedWeekdays) =>
            setSettingsValue("schedule", selectedWeekdays)
          }
          selectedWeekdays={settings?.schedule}
        />
      ),
      isColumn: true,
      isPremium: true,
    },
    {
      label: "Grid distance",
      component: (
        <div className="mt-2 flex flex-col gap-y-4">
          <Slider
            size="lg"
            maxValue={
              isMeters
                ? allSettings?.grid_distance.max
                : metersToFeet(allSettings?.grid_distance.max)
            }
            minValue={
              isMeters
                ? allSettings?.grid_distance.min
                : metersToFeet(allSettings?.grid_distance.min)
            }
            step={
              isMeters
                ? allSettings?.grid_distance.unit
                : metersToFeet(allSettings?.grid_distance.unit)
            }
            value={
              isMeters
                ? settings?.grid_distance
                : metersToFeet(settings?.grid_distance)
            }
            onChange={(newValue) =>
              setSettingsValue(
                "grid_distance",
                isMeters ? newValue : feetToMeters(newValue),
              )
            }
          />
          <NumberInput
            size="sm"
            className="ml-auto w-36"
            maxValue={
              isMeters
                ? allSettings?.grid_distance.max
                : metersToFeet(allSettings?.grid_distance.max)
            }
            minValue={
              isMeters
                ? allSettings?.grid_distance.min
                : metersToFeet(allSettings?.grid_distance.min)
            }
            step={
              isMeters
                ? allSettings?.grid_distance.unit
                : metersToFeet(allSettings?.grid_distance.unit)
            }
            value={
              isMeters
                ? settings?.grid_distance
                : metersToFeet(settings?.grid_distance)
            }
            onChange={(newValue) => {
              setSettingsValue(
                "grid_distance",
                isMeters ? newValue : feetToMeters(newValue),
              );
            }}
            formatOptions={{
              style: "unit",
              unit: isMeters ? "meter" : "foot",
            }}
          />
        </div>
      ),
      isColumn: true,
    },
  ];

  return settingsItems.map((props) => {
    return <SettingsItem key={props.label} {...props} />;
  });
}
