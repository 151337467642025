import { useRef, useState } from "react";
import {
  CardComponent,
  CardNumber,
  CardCVV,
  CardExpiry,
} from "@chargebee/chargebee-js-react-wrapper";
import {
  Button,
  FieldError,
  fieldErrorStyles,
  Input,
  Label,
} from "common/components/base";
import { TextField } from "react-aria-components";
import { tailwindTheme } from "@/src/constants.tsx";
import FieldContainer from "@chargebee/chargebee-js-react-wrapper/dist/components/FieldContainer";
export default function AddCreditCard({
  goBack,
  onTokenReceived,
  isAddingPaymentSource,
  error,
}: {
  goBack: () => void;
  onTokenReceived: (token: string) => void;
  isAddingPaymentSource: boolean;
  error: string | null;
}) {
  const [isTokenizingCard, setIsTokenizingCard] = useState(false);
  const [errorsState, setErrorsState] = useState({
    number: null,
    expiry: null,
    cvv: null,
    name: null,
    global: "",
  });
  const cardRef = useRef<FieldContainer>(null);

  async function onConfirm() {
    if (cardRef.current) {
      setIsTokenizingCard(true);
      cardRef.current
        .tokenize({})
        .catch((error: { message: string }) => {
          setErrorsState({
            ...errorsState,
            global: `Chargebee error: ${error.message}`,
          });
        })
        .then(({ token }: { token: string }) => onTokenReceived(token))
        .finally(() => {
          setIsTokenizingCard(false);
        });
    }
  }
  function onChange(state: {
    field: string;
    error?: { name: string; message: string };
  }) {
    setErrorsState((prevState) => ({
      ...prevState,
      [state.field]: state.error?.message,
    }));
  }

  const inputContainerClassName =
    "flex h-12 cursor-text items-center rounded-full border px-6 transition-[background] hover:bg-grey-100 border-grey-300";

  return (
    <>
      <CardComponent
        // @ts-expect-error: the chargebee type definitions are wrong
        onChange={onChange}
        ref={cardRef}
        styles={{
          base: {
            color: tailwindTheme.colors.grey["700"],
            fontFamily:
              "'Geist', -apple-system, BlinkMacSystemFont, system-ui, sans-serif",
            fontSize: "16px",
            fontWeight: tailwindTheme.fontWeight.medium,

            ":focus": {
              color: tailwindTheme.colors.grey["900"],
            },
            "::placeholder": {
              color: tailwindTheme.colors.grey["400"],
            },
          },
          empty: {},
          invalid: {},
        }}
        showTestCards
        placeholder={{
          number: "Enter card number",
          cvv: "CVV",
          expiry: "MM/YY",
        }}
        classes={{
          complete: "",
          empty: "hover:border-grey-100",
          focus:
            "hover:border-purple-400 border-purple-400 outline outline-3 outline-purple-100",
          invalid: "!border-red-400 !outline-red-100",
        }}
        className="mb-auto"
      >
        <div className="flex flex-col gap-y-4">
          <TextField isInvalid={!!errorsState.name}>
            <Label size="lg">Name on card</Label>
            <Input size="lg" placeholder="John Doe" className="w-full" />
            <FieldError size="lg">{errorsState.name}</FieldError>
          </TextField>
          <TextField isInvalid={!!errorsState.expiry}>
            <Label size="lg">Card number</Label>
            <CardNumber className={inputContainerClassName} />
            <FieldError size="lg">{errorsState.number}</FieldError>
          </TextField>
          <div className="flex gap-x-1.5">
            <TextField isInvalid={!!errorsState.expiry} className="flex-1">
              <Label size="lg">Expires</Label>
              <CardExpiry className={inputContainerClassName} />
              <FieldError size="lg">{errorsState.expiry}</FieldError>
            </TextField>
            <TextField isInvalid={!!errorsState.cvv} className="flex-1">
              <Label size="lg">CVV</Label>
              <CardCVV className={inputContainerClassName} />
              <FieldError size="lg">{errorsState.cvv}</FieldError>
            </TextField>
          </div>
        </div>
      </CardComponent>
      {(errorsState.global || error) && (
        <p className={fieldErrorStyles({ size: "lg" })}>
          {errorsState.global || error}
        </p>
      )}
      <div className="mt-4 flex gap-x-3">
        <Button
          variant="white"
          size="md"
          className="flex-1"
          onPress={goBack}
          isDisabled={isTokenizingCard || isAddingPaymentSource}
        >
          Go back
        </Button>
        <Button
          variant="black"
          size="md"
          className="flex-1"
          onPress={onConfirm}
          isLoading={isTokenizingCard || isAddingPaymentSource}
        >
          Add card
        </Button>
      </div>
    </>
  );
}
