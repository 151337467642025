import { patch } from "@/src/common/helpers/HTTP.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Customer } from "common/types.ts";

type CustomerUpdates = Partial<
  Pick<Customer, "first_name" | "last_name" | "email" | "preferred_unit"> & {
    password: string;
  }
>;
async function updateCustomerRequest(updates: CustomerUpdates) {
  await patch(`v1/customers`, updates);
}
export default function useCustomerMutations() {
  const queryClient = useQueryClient();

  const { mutateAsync: updateCustomer, isPending: isUpdatingCustomer } =
    useMutation({
      mutationFn: (updates: CustomerUpdates) => updateCustomerRequest(updates),
      onSuccess: (_, updates) => {
        queryClient.setQueryData<Customer>(["customer"], (customer) => {
          if (customer) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { password, ...rest } = updates;
            return { ...customer, ...rest };
          }
        });
      },
    });
  return {
    updateCustomer,
    isUpdatingCustomer,
  };
}
