import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post } from "@/src/common/helpers/HTTP.ts";
import { Place } from "common/types.ts";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";

async function addPlaceRequest(
  placeId: string,
  placeName: string,
  paymentSourceId: string,
): Promise<Place> {
  const { location } = await post(`v1/places`, {
    place_id: placeId,
    place_name: placeName,
    payment_source_id: paymentSourceId,
  });
  return location;
}
async function validatePlaceRequest(placeId: string) {
  const { place } = await post(`v1/places/check`, {
    place_id: placeId,
  });
  return place;
}
export default function usePlaceMutations() {
  const queryClient = useQueryClient();

  const { mutateAsync: addPlace, isPending: isAddingPlace } = useMutation({
    mutationFn: ({
      placeId,
      placeName,
      paymentSourceId,
    }: {
      placeId: string;
      placeName: string;
      paymentSourceId: string;
    }) => addPlaceRequest(placeId, placeName, paymentSourceId),
    onSuccess: (newPlace) => {
      queryClient.setQueryData<Place[]>(
        getQueryKeys().places.list(),
        (prevPlaces) => [...(prevPlaces ?? []), newPlace],
      );
    },
  });

  const {
    mutateAsync: validatePlace,
    isPending: isValidatingPlace,
    error: validatePlaceError,
    reset: resetValidatePlace,
  } = useMutation({
    mutationFn: ({ placeId }: { placeId: string }) =>
      validatePlaceRequest(placeId),
  });

  return {
    addPlace,
    isAddingPlace,
    validatePlace,
    isValidatingPlace,
    validatePlaceError,
    resetValidatePlace,
  };
}
