import { PaymentSourcePreview } from "common/components/PaymentSourcePreview";
import { useState } from "react";
import { SelectPaymentMethod } from "common/components/SelectPaymentMethod";
import { Button, DialogHeading, Modal } from "common/components/base";
import useSubscriptionMutations from "common/datahooks/useSubscriptionMutations.tsx";
import { useParams } from "@tanstack/react-router";

export default function PaymentMethod({
  paymentSourceId,
  isLoading,
}: {
  paymentSourceId: string | undefined;
  isLoading: boolean;
}) {
  const [selectedPaymentSourceId, setSelectedPaymentSourceId] =
    useState<string>();
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const { placeId } = useParams({ from: "/$placeId" });
  const {
    updateSubscriptionPaymentSource,
    isUpdatingSubscriptionPaymentSource,
  } = useSubscriptionMutations();

  function onUpdate() {
    if (selectedPaymentSourceId) {
      updateSubscriptionPaymentSource({
        placeId,
        paymentSourceId: selectedPaymentSourceId,
      }).then(() => {
        setIsUpdateOpen(false);
      });
    }
  }

  return (
    <>
      <div className="flex flex-col justify-between rounded-4xl bg-white p-3 max-lg:gap-y-2 lg:flex-row lg:items-start lg:rounded-7xl lg:p-6">
        <div className="flex flex-col gap-y-1.5">
          <h4 className="font-medium">Payment method</h4>
          <p className="text-sm font-medium text-grey-500">
            Select your preferred payment method for future charges
          </p>
        </div>
        <PaymentSourcePreview
          paymentSourceId={paymentSourceId}
          onUpdatePaymentSource={() => {
            setIsUpdateOpen(true);
          }}
          isLoading={isLoading}
        />
      </div>
      <Modal
        variant="white"
        isOpen={isUpdateOpen}
        setIsOpen={setIsUpdateOpen}
        className="flex-col md:h-[540px]"
      >
        <DialogHeading className="mb-4 text-2xl font-medium max-lg:mx-auto max-lg:py-1.5 lg:mb-6 lg:text-xl lg:font-semibold">
          Payment details
        </DialogHeading>
        <SelectPaymentMethod
          setSelectedPaymentSourceId={setSelectedPaymentSourceId}
          selectedPaymentSourceId={selectedPaymentSourceId}
          footerContent={
            <div className="mt-2 flex justify-between">
              <Button
                variant="white"
                size="lg"
                onPress={() => setIsUpdateOpen(false)}
                isDisabled={isUpdatingSubscriptionPaymentSource}
              >
                Back
              </Button>
              <Button
                variant="black"
                size="lg"
                isLoading={isUpdatingSubscriptionPaymentSource}
                onPress={onUpdate}
              >
                Update
              </Button>
            </div>
          }
        />
      </Modal>
    </>
  );
}
