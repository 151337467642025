import {
  FieldError as AriaFieldError,
  FieldErrorProps as AriaFieldErrorProps,
} from "react-aria-components";
import { clsx } from "clsx";
import { tv, VariantProps } from "tailwind-variants";

export const fieldErrorStyles = tv({
  base: "mt-1 text-red-400",
  variants: {
    size: {
      lg: "text-sm",
      md: "text-xs",
      sm: "text-2xs",
    },
  },
});
interface FieldErrorProps
  extends VariantProps<typeof fieldErrorStyles>,
    AriaFieldErrorProps {}
export function FieldError({ size, ...rest }: FieldErrorProps) {
  return (
    <AriaFieldError className={clsx(fieldErrorStyles({ size }))} {...rest} />
  );
}
