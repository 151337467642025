import {
  Button,
  buttonStyles,
  Skeleton,
  UnstyledButton,
} from "common/components/base";
import usePaymentSources from "common/datahooks/usePaymentSources.ts";
import { CARD_ICONS } from "@/src/constants.tsx";

import Pen from "fontawesome/solid/pen.svg?react";
import Plus from "fontawesome/regular/plus.svg?react";
import Paypal from "fontawesome/brands/cc-paypal.svg?react";

export function PaymentSourcePreview({
  paymentSourceId,
  onUpdatePaymentSource,
  isLoading,
}: {
  paymentSourceId: string | undefined;
  onUpdatePaymentSource: () => void;
  isLoading: boolean;
}) {
  const { paymentSources } = usePaymentSources();

  const paymentSource = paymentSources?.find(
    ({ id }) => id === paymentSourceId,
  );

  if (paymentSourceId || isLoading) {
    let content;
    if (paymentSource && !isLoading) {
      const Icon =
        paymentSource.type === "paypal_express_checkout"
          ? Paypal
          : CARD_ICONS[paymentSource.card.brand];

      content = (
        <>
          <Icon className="size-7" />
          <div className="flex flex-col gap-y-1">
            <span className="text-sm font-semibold">
              {paymentSource.type === "card"
                ? `Card ending in ${paymentSource.card.last4}`
                : paymentSource.paypal.email}
            </span>
            {paymentSource.type === "card" && (
              <span className="text-xs text-grey-500">
                Expiry on {paymentSource.card.expiry_month}/
                {paymentSource.card.expiry_year}
              </span>
            )}
          </div>
          <Button
            className="ml-auto"
            isIconOnly
            size="md"
            variant="white"
            onPress={onUpdatePaymentSource}
          >
            <Pen />
          </Button>
        </>
      );
    } else {
      content = (
        <>
          <Skeleton className="size-7 rounded-md" />
          <Skeleton className="h-5 w-36 rounded-full" />
          <Skeleton className="ml-auto size-11 rounded-full" />
        </>
      );
    }
    return (
      <div className="flex items-center gap-x-3 rounded-3xl border border-grey-200 bg-white px-3.5 py-3 lg:min-w-96">
        {content}
      </div>
    );
  }
  return (
    <UnstyledButton
      onPress={onUpdatePaymentSource}
      className="flex items-center gap-x-3 rounded-full border border-dashed border-grey-300 px-3.5 py-3 data-[hovered]:bg-grey-50 lg:min-w-96"
    >
      <div className={buttonStyles({ size: "md", variant: "white" })}>
        <Plus />
      </div>
      <span className="text-sm font-semibold">Add new payment method</span>
    </UnstyledButton>
  );
}
