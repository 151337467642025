import usePlaces from "@/src/common/datahooks/usePlaces.ts";
import { NavigateOptions } from "@tanstack/react-router";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKeys } from "@/src/common/helpers/getQueryKeys.ts";
import { DetailedPlace } from "common/types.ts";
import { useSelectedPlace } from "@/src/contexts.ts";
import {
  Popover,
  ButtonLink,
  RouterLink,
  PlaceAvatar,
  Button,
  PopoverDialog,
  UnstyledButton,
} from "common/components/base";
import { DialogTrigger } from "react-aria-components";

import ArrowUpRight from "fontawesome/regular/arrow-up-right.svg?react";
import ChevronDown from "fontawesome/regular/chevron-down.svg?react";
import Plus from "fontawesome/regular/plus.svg?react";
import ArrowRight from "fontawesome/regular/arrow-right.svg?react";
import { useAppStore } from "common/hooks";
import { useEffect, useState } from "react";

export default function PlaceSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedPlace } = useSelectedPlace();
  const { places } = usePlaces();
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsOpen(false);
  }, [selectedPlace]);

  const avatarComponent = (
    <PlaceAvatar placeName={selectedPlace.name} size="lg" />
  );
  return (
    <>
      <RouterLink className="rounded-full lg:hidden" to="/profiles">
        {avatarComponent}
      </RouterLink>
      <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
        <UnstyledButton className="group flex w-64 items-center rounded-full max-lg:hidden">
          {avatarComponent}
          <div className="flex grow items-center gap-x-2 overflow-hidden rounded-full bg-white px-5 py-1.5 group-data-[hovered]:bg-grey-50">
            <div className="flex grow flex-col overflow-hidden">
              <span className="truncate text-left text-sm font-medium text-grey-900">
                {selectedPlace.name}
              </span>
              <span className="truncate text-left text-xs text-grey-500">
                {selectedPlace.address}
              </span>
            </div>
            <ChevronDown className="size-3 group-aria-expanded:rotate-180" />
          </div>
        </UnstyledButton>
        <Popover
          className="w-64 flex-col items-stretch px-0.5"
          placement="bottom end"
        >
          <PopoverDialog>
            {({ close }) => (
              <>
                <div className="flex items-center px-3 py-4">
                  <div className="flex grow flex-col">
                    <span className="text-sm font-medium">All GBP</span>
                    <span className="text-xs font-medium text-grey-500">
                      {places ? places.length : "No"} GBP added
                    </span>
                  </div>
                  <ButtonLink
                    variant="black"
                    size="md"
                    isIconOnly
                    to="/profiles"
                  >
                    <ArrowUpRight />
                  </ButtonLink>
                </div>
                {places && (
                  <div className="flex max-h-[312px] flex-col overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey-200">
                    {places.map(({ id, name, address }) => {
                      const navigateOptions: NavigateOptions = {
                        to: "/$placeId/home",
                        params: { placeId: id },
                      };
                      const existingPlace =
                        queryClient.getQueryData<DetailedPlace>(
                          getQueryKeys().places.details(id),
                        );
                      if (existingPlace) {
                        const activeKeyword = existingPlace.keywords.find(
                          ({ grid_state }) =>
                            grid_state === "failed" || grid_state === "ready",
                        );
                        if (activeKeyword) {
                          navigateOptions.search = {
                            selectedKeywordId: activeKeyword.id,
                          };
                        }
                      }
                      return (
                        <RouterLink
                          key={id}
                          {...navigateOptions}
                          className="group flex items-center gap-x-2 rounded-full py-2 pl-2.5 pr-5 outline-grey-100 data-[hovered]:bg-grey-50 data-[focused]:outline-2"
                        >
                          <PlaceAvatar placeName={name} size="sm" />
                          <div className="flex grow flex-col overflow-hidden">
                            <span className="truncate text-sm font-medium">
                              {name}
                            </span>
                            <span className="truncate text-xs text-grey-500">
                              {address}
                            </span>
                          </div>
                          <ArrowRight className="invisible size-3 group-data-[hovered]:visible" />
                        </RouterLink>
                      );
                    })}
                  </div>
                )}
                <Button
                  variant="stroke"
                  size="md"
                  className="mx-3 my-4"
                  onPress={() => {
                    useAppStore.getState().setIsAddModalOpen(true);
                    close();
                  }}
                >
                  <Plus />
                  Add new GBP
                </Button>
              </>
            )}
          </PopoverDialog>
        </Popover>
      </DialogTrigger>
    </>
  );
}
