import { useEffect, useState } from "react";
import AddCreditCard from "common/components/SelectPaymentMethod/AddCreditCard.tsx";
import PaypalButton from "common/components/SelectPaymentMethod/PaypalButton.tsx";
import { Button, ButtonGroup } from "common/components/base";

import CreditCard from "fontawesome/solid/credit-card.svg?react";
import Paypal from "@/src/assets/paypal-logo.svg?react";
import usePaymentSourcesMutations from "common/datahooks/usePaymentSourcesMutations.tsx";
import useBraintreeToken from "common/datahooks/useBraintreeToken.ts";
import { HTTPError } from "common/helpers/HTTP.ts";

const options = [
  {
    key: "card",
    content: (
      <>
        <CreditCard className="mr-1.5 size-4" /> Card
      </>
    ),
  },
  { key: "paypal", content: <Paypal /> },
] as const;
export default function AddPaymentMethod({
  goBack,
}: {
  goBack: (paymentSourceId?: string) => void;
}) {
  const [braintreeClient, setBraintreeClient] = useState<BraintreeClient>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    "paypal" | "card"
  >("card");
  const { addPaymentSource, isAddingPaymentSource, addPaymentSourceError } =
    usePaymentSourcesMutations();
  const { braintreeToken } = useBraintreeToken();

  function onTokenReceived(token: string) {
    addPaymentSource({ source: selectedPaymentMethod, token }).then(
      (paymentSource) => {
        goBack(paymentSource.id);
      },
    );
  }

  useEffect(() => {
    if (braintreeToken) {
      braintree.client
        .create({
          authorization: braintreeToken,
        })
        .then((clientInstance) => {
          setBraintreeClient(clientInstance);
        });
    }
  }, [braintreeToken]);

  return (
    <>
      <ButtonGroup
        className="mb-4"
        items={options}
        selectedKey={selectedPaymentMethod}
        setSelectedKey={setSelectedPaymentMethod}
      />
      {selectedPaymentMethod === "card" ? (
        <AddCreditCard
          goBack={goBack}
          onTokenReceived={onTokenReceived}
          isAddingPaymentSource={isAddingPaymentSource}
          error={
            addPaymentSourceError instanceof HTTPError
              ? addPaymentSourceError.serverMessage
              : null
          }
        />
      ) : (
        <>
          <PaypalButton
            braintreeClient={braintreeClient}
            onTokenReceived={onTokenReceived}
          />
          <Button
            variant="white"
            size="md"
            className="mt-6 w-full"
            onPress={() => goBack()}
          >
            Go back
          </Button>
        </>
      )}
    </>
  );
}
