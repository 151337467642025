import { queryOptions, useQuery } from "@tanstack/react-query";
import { Place } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/common/helpers/getQueryKeys.ts";
import { dummyPlaces } from "@/src/dummyGenerator.ts";

async function getPlacesRequest(): Promise<Place[]> {
  const { places } = await get(`v1/places`);
  return (<Place[]>places).sort(
    (place1, place2) =>
      place1.subscription.created_at - place2.subscription.created_at,
  );
}
export const placesQueryOptions = queryOptions({
  queryKey: getQueryKeys().places.list(),
  queryFn: () => getPlacesRequest(),
  refetchOnMount: false,
});
export default function usePlaces() {
  const {
    data: places,
    isLoading: isLoadingPlaces,
    refetch: refetchPlaces,
  } = useQuery(placesQueryOptions);

  return {
    places: places && places.length ? places : dummyPlaces,
    isLoadingPlaces,
    refetchPlaces,
  };
}
