import { useQuery } from "@tanstack/react-query";
import { get } from "common/helpers/HTTP.ts";
import { BillingFrequency } from "common/types.ts";

async function getPlanPricingRequest(): Promise<
  Record<BillingFrequency, number>
> {
  const { items } = await get(`v1/billing/items`);
  const { item_prices } = items.find(
    ({ id }: { id: string }) => id === "Starter-Plan",
  );

  return {
    month: item_prices.find(
      ({ period_unit }: { period_unit: string }) => period_unit === "month",
    ).price,
    year: item_prices.find(
      ({ period_unit }: { period_unit: string }) => period_unit === "year",
    ).price,
  };
}
export default function usePlanPricing() {
  const { data: planPricing, isLoading: isLoadingPlanPricing } = useQuery({
    queryKey: ["pricing"],
    refetchOnMount: false,
    queryFn: getPlanPricingRequest,
  });
  return { planPricing, isLoadingPlanPricing };
}
