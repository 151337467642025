import { clsx } from "clsx";

const strokeDashArray = 340;

//difficulty is a number from 0 to 100
function getDashOffset(difficult: number) {
  return (
    strokeDashArray / 2 +
    ((strokeDashArray - strokeDashArray / 2) / (0 - 1)) * (difficult / 100 - 1)
  );
}

export default function RankProgress({
  selectedDifficulty,
  compareWithDifficulty,
}: {
  selectedDifficulty: number;
  compareWithDifficulty: number | undefined;
}) {
  let topBarClassName,
    bottomBarClassName,
    topBarDashOffset,
    bottomBarDashOffset;

  if (compareWithDifficulty && compareWithDifficulty !== selectedDifficulty) {
    //increase
    if (compareWithDifficulty > selectedDifficulty) {
      topBarClassName = "stroke-red-300";
      bottomBarClassName = "stroke-grey-200";
      topBarDashOffset = getDashOffset(selectedDifficulty);
      bottomBarDashOffset = getDashOffset(compareWithDifficulty);
    } else {
      topBarClassName = "stroke-green-100";
      bottomBarClassName = "stroke-green-300";
      topBarDashOffset = getDashOffset(compareWithDifficulty);
      bottomBarDashOffset = getDashOffset(selectedDifficulty);
    }
  } else {
    topBarDashOffset = getDashOffset(selectedDifficulty);
    topBarClassName =
      selectedDifficulty > 50 ? "stroke-green-300" : "stroke-red-300";
    bottomBarClassName = "hidden";
  }

  return (
    <svg
      className="absolute max-lg:hidden"
      width="128"
      height="64"
      viewBox="0 0 128 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="cut-off" className="origin-center rotate-180">
          <path d="M6 64C2.68629 64 -0.0291595 61.3079 0.281044 58.0087C0.878136 51.6585 2.42228 45.4217 4.87171 39.5083C8.08801 31.7434 12.8022 24.6881 18.7452 18.7452C24.6881 12.8022 31.7434 8.08801 39.5083 4.87171C47.2731 1.65541 55.5954 -3.67377e-07 64 0C72.4046 3.67377e-07 80.7269 1.65541 88.4917 4.87171C96.2566 8.08801 103.312 12.8022 109.255 18.7452C115.198 24.6881 119.912 31.7434 123.128 39.5083C125.578 45.4217 127.122 51.6585 127.719 58.0087C128.029 61.3079 125.314 64 122 64H114.8C111.486 64 108.841 61.3019 108.399 58.0179C107.883 54.1911 106.874 50.4387 105.39 46.8558C103.138 41.4204 99.8384 36.4817 95.6784 32.3216C91.5183 28.1616 86.5796 24.8616 81.1442 22.6102C75.7088 20.3588 69.8832 19.2 64 19.2C58.1168 19.2 52.2912 20.3588 46.8558 22.6102C41.4204 24.8616 36.4817 28.1616 32.3216 32.3216C28.1616 36.4817 24.8616 41.4204 22.6102 46.8558C21.1261 50.4387 20.1168 54.1911 19.6012 58.0179C19.1587 61.3019 16.5137 64 13.2 64H6Z" />
        </clipPath>
      </defs>
      <path
        className="fill-grey-50"
        d="M6 64C2.68629 64 -0.0291595 61.3079 0.281044 58.0087C0.878136 51.6585 2.42228 45.4217 4.87171 39.5083C8.08801 31.7434 12.8022 24.6881 18.7452 18.7452C24.6881 12.8022 31.7434 8.08801 39.5083 4.87171C47.2731 1.65541 55.5954 -3.67377e-07 64 0C72.4046 3.67377e-07 80.7269 1.65541 88.4917 4.87171C96.2566 8.08801 103.312 12.8022 109.255 18.7452C115.198 24.6881 119.912 31.7434 123.128 39.5083C125.578 45.4217 127.122 51.6585 127.719 58.0087C128.029 61.3079 125.314 64 122 64H114.8C111.486 64 108.841 61.3019 108.399 58.0179C107.883 54.1911 106.874 50.4387 105.39 46.8558C103.138 41.4204 99.8384 36.4817 95.6784 32.3216C91.5183 28.1616 86.5796 24.8616 81.1442 22.6102C75.7088 20.3588 69.8832 19.2 64 19.2C58.1168 19.2 52.2912 20.3588 46.8558 22.6102C41.4204 24.8616 36.4817 28.1616 32.3216 32.3216C28.1616 36.4817 24.8616 41.4204 22.6102 46.8558C21.1261 50.4387 20.1168 54.1911 19.6012 58.0179C19.1587 61.3019 16.5137 64 13.2 64H6Z"
      />
      {/*bottom bar*/}
      <circle
        r="55"
        cx="64"
        cy="0"
        strokeWidth="22"
        clipPath="url(#cut-off)"
        className={clsx(
          "origin-center rotate-180 transition-[stroke-dashoffset]",
          bottomBarClassName,
        )}
        strokeDashoffset={bottomBarDashOffset}
        fill="transparent"
        strokeDasharray={strokeDashArray}
      />
      {/*top bar*/}
      <circle
        r="55"
        cx="64"
        cy="0"
        strokeWidth="22"
        clipPath="url(#cut-off)"
        className={clsx(
          "origin-center rotate-180 transition-[stroke-dashoffset]",
          topBarClassName,
        )}
        strokeDashoffset={topBarDashOffset}
        fill="transparent"
        strokeDasharray={strokeDashArray}
      />
    </svg>
  );
}
