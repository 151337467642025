import {
  SearchFieldProps as AriaSearchFieldProps,
  SearchField as AriaSearchField,
} from "react-aria-components";
import { Input, InputButton, Label } from "common/components/base";
import { InputGroup } from "../Input";

import XMark from "fontawesome/regular/xmark.svg?react";
import MagnifyingGlass from "fontawesome/regular/magnifying-glass.svg?react";
import { tv } from "tailwind-variants";
import { clsx } from "clsx";

const inputStyle = tv({
  base: "flex-1",
  variants: {
    size: {
      lg: "pl-10 pr-14",
      md: "pl-8.5 pr-12",
      sm: "pl-7 pr-8.5",
    },
  },
});
export interface SearchFieldProps extends Omit<AriaSearchFieldProps, "size"> {
  size: "sm" | "md" | "lg";
  label?: string;
  placeholder?: string;
}
export function SearchField({
  size,
  placeholder,
  label,
  className,
  ...rest
}: SearchFieldProps) {
  return (
    <AriaSearchField className={clsx("group/searchfield", className)} {...rest}>
      {label && <Label size={size}>{label}</Label>}
      <InputGroup size={size}>
        <MagnifyingGlass />
        <Input
          size={size}
          placeholder={placeholder}
          className={inputStyle({ size })}
        />
        <InputButton
          size={size}
          className="group-data-[empty]/searchfield:hidden"
        >
          <XMark />
        </InputButton>
      </InputGroup>
    </AriaSearchField>
  );
}
