import AddKeyword from "@/src/pages/home/ManageKeywords/AddKeyword.tsx";
import { Keyword } from "common/types.ts";
import { Badge, Button, Pagination } from "common/components/base";
import { usePagination } from "common/hooks";
import ArrowRight from "fontawesome/regular/arrow-right.svg?react";
import TrashCan from "fontawesome/regular/trash-can.svg?react";
import { useNavigate } from "@tanstack/react-router";
import DeleteKeywordButton from "@/src/pages/home/ManageKeywords/DeleteKeywordButton.tsx";

type NewKeyword = Pick<Keyword, "label" | "id"> & { isCustom: true };
export default function KeywordsList({
  keywords,
  keywordSuggestions,
  maxKeywords,
  searchText,
  closeModal,
}: {
  keywords: Keyword[];
  closeModal: () => void;
} & (
  | {
      keywordSuggestions?: never;
      maxKeywords?: never;
      searchText?: never;
    }
  | {
      keywordSuggestions: Keyword[];
      maxKeywords: number;
      searchText: string;
    }
)) {
  const navigate = useNavigate({ from: "/$placeId/home" });

  const listData: (Keyword | NewKeyword)[] = keywordSuggestions || keywords;

  if (
    keywordSuggestions &&
    searchText &&
    !keywordSuggestions.some(({ label }) => label.toLowerCase() === searchText)
  ) {
    listData.unshift({ id: "custom", isCustom: true, label: searchText });
  }

  const { paginationComponentProps, pageItems, isPaginationVisible } =
    usePagination(8, listData);

  return (
    <>
      <div className="flex flex-col divide-y divide-grey-200 overflow-y-auto lg:hidden">
        {pageItems.map((keyword) => {
          const { id, label } = keyword;

          let searchVolumeContent = "N/A";
          let difficultyContent = (
            <Badge variant="grey" size="md">
              ?
            </Badge>
          );

          const isCustomKeyword = "isCustom" in keyword;

          if (!isCustomKeyword) {
            const { state, search_volume, difficulty } = keyword;

            if (state === "ready" && search_volume && difficulty) {
              difficultyContent = (
                <Badge variant="green" size="md">
                  {difficulty}
                </Badge>
              );
              searchVolumeContent = search_volume.toString();
            }
          }

          const addedKeywordId = keywords.find(
            (keyword) => keyword.label === label,
          )?.id;

          return (
            <div className="flex gap-x-3 px-3 py-2">
              {difficultyContent}
              <div className="flex grow flex-col">
                <span className="text-base font-medium">{label}</span>
                <span className="text-sm text-grey-500">
                  Search volume: {searchVolumeContent}
                </span>
              </div>
              {keywordSuggestions ? (
                <AddKeyword
                  isLimitReached={keywords.length > maxKeywords}
                  newKeywordData={isCustomKeyword ? { label } : { id }}
                  addedKeywordId={addedKeywordId}
                  closeModal={closeModal}
                />
              ) : (
                <div className="flex gap-x-1.5">
                  <DeleteKeywordButton
                    keywordId={id}
                    renderDeleteButton={(onPress) => (
                      <Button
                        variant="transparent"
                        size="md"
                        isIconOnly
                        onPress={onPress}
                      >
                        <TrashCan className="size-3.5" />
                      </Button>
                    )}
                  />
                  <Button
                    variant="white"
                    size="md"
                    isIconOnly
                    onPress={() => {
                      navigate({
                        search: {
                          selectedKeywordId: id,
                        },
                      });
                      closeModal();
                    }}
                  >
                    <ArrowRight className="size-3.5" />
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {isPaginationVisible && (
        <Pagination
          size="md"
          className="mx-auto my-4"
          {...paginationComponentProps}
        />
      )}
    </>
  );
}
